import axios from 'axios';
import { getField, updateField } from 'vuex-map-fields';
import endPoints from '../../../config/endPoints';
import { isEmptyFilter } from '../../../util/filter';
import defaultItem from './default_withdrawal'
import defaultFilter from './default_withdrawal_filter'

const formatDate = (d) => {
    var day, month, year;
    month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}

const buildTimeString = (avg) => {
    // calculate (and subtract) whole days
    var days = Math.floor(avg / 86400);
    avg -= days * 86400;

    // calculate (and subtract) whole hours
    var hours = Math.floor(avg / 3600) % 24;
    avg -= hours * 3600;
    if (hours.toString().length == 1) {
        hours = '0' + hours.toString()
    }

    // calculate (and subtract) whole minutes
    var minutes = Math.floor(avg / 60) % 60;
    avg -= minutes * 60;
    if (minutes.toString().length == 1) {
        minutes = '0' + minutes.toString()
    }

    // what's left is seconds
    var seconds = avg % 60;
    seconds = parseInt(seconds)
    if (seconds.toString().length == 1) {
        seconds = '0'.concat(seconds.toString())
    }

    return hours + ":" + minutes + ":" + seconds;
}

export default {
    namespaced: true,
    state: {
        alert_itens: {},
        avg_time: 0,
        selected_bank: {},
        block_automatic: false,
        block_refuse: false,
        blocked_itens: [],
        bankAutomatic: '',
        bankPayment: null,
        banks: [],
        contact: {},
        contact_show: {},
        dialog: false,
        dialogContact: false,
        dialogReverse: false,
        dialogDelete: false,
        dialogReorder: false,
        dialogConfirmFinishWithdrawal: false,
        dialogFinishWithdrawal: false,
        dialogReceipt: false,
        dialogFilter: false,
        dialogPrint: false,
        dialogAutomaticWithdrawal: false,
        dialogBlacklistWithdrawals: false,
        dialogRefuseWithdrawal: false,
        dialogHistory: false,
        dialogUpdate: false,
        editedItem: {},
        errorPositiveBalance: false,
        errors: [],
        filter: {
            id: '',
            iddApp: '',
            idClub: '',
            inscription: '',
            name: '',
            idSubAccount: '',
            value: '',
            status: '',
            createdAt: '',
            type: '',
            finishAt: '',
            type_send: '',
            isApp: '',
            platform: ''
        },
        filtered: false,
        forceBank: false,
        historyItems: [],
        instances: [],
        items: [],
        itemToDelete: {},
        itemToReverse: {},
        loading: false,
        loadingPix: false,
        mode: 'LIST',
        message: '',
        overlay: false,
        page: 1,
        pagination: {
            page: 1,
            itemsPerPage: 10
        },
        receipt_show: '',
        print: '',
        profile_permissions: {},
        screen: 'withdrawal',
        notificationMessage: '',
        notificationType: "success",
        showNotification: false,
        updatingWithdrawal: false,
        updateStatus: 8,
        totalItems: 0,
        totalValue: 0,
        rowsPerPage: 10,
        withdrawalAutomatic: {},
        withdrawalRefused: {},
        withdrawalBlocksAlertsToHandle: {},
        withdrawalHistory: {},
        withdrawalTotals: {
            pending_value: 0,
            chips_withdraw_value: 0,
            scheduled_value: 0,
            processing_value: 0,
            bank_error_value: 0,
            pending_approval_value: 0,
            total_open_value: 0
        },
        withdrawalPix: [],
    },
    mutations: {
        add: (state, payload) => {
            state.mode = "ADD"
            state.screen = payload.screen
            state.errorPositiveBalance = false
            if (payload.withdrawal) {
                state.editedItem = payload.withdrawal
                state.message = payload.message
                state.contact = payload.contact
                state.instance_name = payload.instance_name
            } else {
                state.dialog = true
                state.editedItem = Object.assign({}, defaultItem)
                state.message = ""
                state.contact = ""
                state.instance_name = ""
            }
        },
        edit: (state, payload) => {
            state.editedItem = Object.assign({}, payload.withdrawal)
            state.editedIndex = state.items.indexOf(payload.withdrawal)
            state.screen = payload.screen
            state.editedItem.receipt_file = {}
            if (state.screen != "messages") {
                state.dialog = true
                state.content = ""
                state.contact = ""
                state.instance_name = ""
            } else {
                state.message = payload.message
                state.contact = payload.contact
                state.instance_name = payload.instance_name
            }
            state.updatingWithdrawal = true
            state.mode = "EDIT"
        },
        finishWithdrawal: (state, payload) => {

            state.editedItem = Object.assign({}, payload.withdrawal)
            state.screen = payload.screen
            state.editedItem.status = 2

            state.editedIndex = state.items.indexOf(payload.withdrawal)

            state.dialog = true
            state.mode = "FINISH"
        },
        closeDialog: (state) => {
            state.editedItem = {}
            state.editedIndex = -1
            state.errors = [];
            state.updatingWithdrawal = false
            state.mode = "LIST"
        },
        closeReverseDialog: (state) => {
            state.dialogReverse = false
            state.itemToReverse = {}
            state.mode = "LIST"
        },
        loading: (state, loading) => state.loading = loading,
        loadingPix: (state, payload) => state.loadingPix = payload.show,
        overlay: (state, payload) => {
            state.overlay = payload.show
        },
        showOverlay: (state, payload) => {
            state.overlay = payload.show
        },
        setAlertItens: (state, payload) => {
            console.log("Resultados e alertas: ", payload)
            state.alert_itens = payload
        },
        setAvgTime: (state, payload) => state.avg_time = payload.avg_time,
        setBlockedAutomatic: (state, payload) => state.block_automatic = payload.block,
        setBlockRefused: (state, payload) => state.block_refuse = payload.block,
        setBlockedItens: (state, items) => state.blocked_itens = items,
        setInstances: (state, payload) => state.instances = payload.instances,
        setBanks: (state, banks) => state.banks = banks,
        setBankedItem: (state, payload) => state.editedItem.bank = payload.bank,
        setMode: (state, mode) => state.mode = mode,
        setItems: (state, items) => state.items = items,
        setHistoryItems: (state, historyItems) => state.historyItems = historyItems,
        setItemToDelete: (state, item) => state.itemToDelete = item,
        setItemToReverse: (state, item) => state.itemToReverse = item,
        setTotalItems: (state, total) => state.totalItems = total,
        setTotalValue: (state, total) => state.totalValue = total,
        setRowsPerPage: (state, rowsPerPage) => state.rowsPerPage = rowsPerPage,
        showDialog: (state, show) => state.dialog = show,
        setDialogDelete: (state, show) => state.dialogDelete = show,
        setDialogReverse: (state, show) => state.dialogReverse = show,
        setDialogReorder: (state, show) => state.dialogReorder = show,
        setDialogConfirmFinishWithdrawal: (state, show) => state.dialogConfirmFinishWithdrawal = show,
        setEditedItem: (state, item) => state.editedItem = item,
        setDialogReceipt: (state, payload) => state.dialogReceipt = payload.show,
        setDialogContact: (state, payload) => state.dialogContact = payload.show,
        setFilter: (state, filter) => state.filter = filter,
        setFiltered: (state, filtered) => state.filtered = filtered,
        setForceBank: (state, payload) => state.forceBank = payload.forceBank,
        setContactShow: (state, payload) => state.contact_show = payload.contact,
        setProfilePermissions: (state, payload) => state.profile_permissions = payload,
        setReceipt: (state, payload) => state.receipt_show = payload.receipt,
        setScreen: (state, payload) => state.screen = payload.screen,
        showFilter: (state, payload) => state.dialogFilter = payload.show,
        showLoading: (state, show) => state.loading = show,
        showDialogBlacklistWithdrawals: (state, payload) => state.dialogBlacklistWithdrawals = payload.show,
        showDialogHistory: (state, payload) => state.dialogHistory = payload.show,
        showDialogUpdate: (state, payload) => state.dialogUpdate = payload.show,
        showNotification: (state, payload) => {
            state.showNotification = payload.show,
                state.notificationMessage = payload.message,
                state.notificationType = payload.type
        },
        showDialogRefuseWithdrawal: (state, payload) => {
            state.dialogRefuseWithdrawal = payload.show
        },
        showDialogAutomaticWithdrawal: (state, payload) => {
            state.dialogAutomaticWithdrawal = payload.show
        },
        setWithdrawalAutomatic: (state, payload) => {
            state.withdrawalAutomatic = payload
        },
        setErrorPositiveBalance: (state, payload) => {
            state.errorPositiveBalance = payload
        },
        setWithdrawalHistory: (state, payload) => {
            state.withdrawalHistory = payload.withdrawal
        },
        setWithdrawalRefuse: (state, payload) => {
            state.withdrawalRefused = payload 
            state.withdrawalRefused.refund = true
        },
        setWithdrawaBlocksAlertsToHandle: (state, payload) => {
            state.withdrawalBlocksAlertsToHandle = payload
        },
        setUpdatingWithdrawal: (state, payload) => {
            state.updatingWithdrawal = payload.updatingWithdrawal
        },
        setPrint: (state, payload) => {
            state.print = payload.print
            state.dialogPrint = true
        },
        showDialogPrint(state, payload) {
            state.dialogPrint = payload.show
        },
        setWithdrawalPix: (state, payload) => {
            state.withdrawalPix = payload.items
        },
        setWithdrawalTotals: (state, payload) => {
            state.withdrawalTotals = payload.withdrawalTotals
        },
        updateField,
        viewItem(state, item) {
            state.editedItem = item
            state.editedIndex = state.items.indexOf(item)
            state.dialog = true
            state.mode = "VIEW"
        }
    },
    getters: {
        getField,
        isEmptyFilter: state => {
            return Object.keys(state.filter).every(key => !state.filter[key])
        }
    },
    actions: {
        checkPixPayment: ({ commit, dispatch }, payload) => {
            let url = endPoints.withdrawals24h + '/check_payment/' + payload.withdrawal.id
            axios.put(url).then(() => {
                let notification = {
                    show: true,
                    message: "Saque atualizado com sucesso",
                    type: "success"
                }
                dispatch('getItems', { resetPage: true })
                commit('showNotification', notification)
            })
        },
        clearFilter: ({ commit, dispatch }) => {
            commit('setFilter', Object.assign({}, defaultFilter))
            commit('setFiltered', false)
            commit('showFilter', { show: false })
            commit('overlay', { show: true })

            setTimeout(function () {
                commit('overlay', { show: false })
                dispatch('getItems')
            }, 1000);
        },
        closeDialogConfirmFinishWithdrawal: ({ commit }) => {
            commit('setDialogConfirmFinishWithdrawal', false)
            commit('setEditedItem', {})
        },
        closeReorderDialog: ({ commit }) => {
            commit('setDialogReorder', false)
            commit('setEditedItem', {})
        },
        showDialogReorder: ({ commit }, payload) => {
            commit('setDialogReorder', true)
            commit('setEditedItem', payload.item)
        },
        showDialogConfirmFinishWithdrawal: ({ commit }, payload) => {
            console.log(payload)
            commit('setDialogConfirmFinishWithdrawal', true)
            commit('setEditedItem', payload.item)
        },
        openDialogUpdate: ({ commit }, payload) => {
            commit('showDialogUpdate', { show: true })
            commit('setEditedItem', payload.item)
        },
        closeDialogUpdate: ({ commit }) => {
            commit('showDialogUpdate', { show: false })
            commit('setEditedItem', {})
        },
        closeDialog: (({ commit, state }) => {
            state.editedItem = {}
            if (state.screen != "messages") {
                commit('showDialog', false)
            } else {
                commit('message/showDialogWithdrawal', { show: false }, { root: true })
            }

            commit('closeDialog')
        }),
        closeDeleteDialog: ({ commit }) => {
            commit('setDialogDelete', false)
            commit('setItemToDelete', {})
        },
        deleteItem: ({ dispatch, commit, state }) => {
            commit('loading', true)
            axios.delete(endPoints.withdrawals24h + "/" + state.itemToDelete.id, state.itemToDelete).then(() => {
                dispatch('closeDeleteDialog')
                commit('loading', false)
                return dispatch('getItems');

            });
        },
        updateStatusWithdrawal: ({ state, dispatch }) => {
            state.mode = "EDIT"
            state.editedItem.status = state.updateStatus
            dispatch('save', { close: false })
            dispatch('closeDialogUpdate')
        },
        finishWithdrawalAnnotated: ({ state, dispatch }, payload) => {

            state.editedItem = Object.assign({}, payload.withdrawal)
            state.screen = payload.screen
            state.editedItem.status = 2

            state.editedIndex = state.items.indexOf(payload.withdrawal)

            state.dialog = true
            state.mode = "FINISH"
            dispatch('save', { close: true })
        },
        reorder: ({ state, commit, dispatch }) => {
            if (state.editedItem.status == 0 && state.editedItem.typeSend == 3) {
                const url = endPoints.withdrawals24h + "/create_order" + "/" + state.editedItem.id;
                axios.post(url, state.editedItem).then(() => {
                    let notification = {
                        show: true,
                        message: "Ordem criada com sucesso",
                        type: "success"
                    }
                    dispatch('getItems', { resetPage: true })
                    dispatch('closeReorderDialog')
                    commit('showNotification', notification)
                }).catch((error) => {
                    let notification = {
                        show: true,
                        message: error.response.data.message,
                        type: "error"
                    }
                    commit('showNotification', notification)
                })

            } else {
                const url = endPoints.withdrawals24h + "/reorder";
                axios.put(url, state.editedItem).then(() => {
                    let notification = {
                        show: true,
                        message: "Ordem reenviada com sucesso",
                        type: "success"
                    }
                    dispatch('getItems', { resetPage: true })
                    dispatch('closeReorderDialog')
                    commit('showNotification', notification)
                }).catch((error) => {
                    let notification = {
                        show: true,
                        message: error.response.data.message,
                        type: "error"
                    }
                    commit('showNotification', notification)
                })
            }

        },
        getItems: ({ commit, state }) => {
            if (state.loading) {
                return
            }
            commit('loading', true)
            let url = endPoints.withdrawals24h;
            let filter = state.filter;
            url += '?page=' + state.pagination.page + '&' + 'limit=' + state.pagination.itemsPerPage;

            commit('setFiltered', false)
            Object.keys(filter).forEach(function (field) {
                if (filter[field] !== "" && filter[field] !== undefined) {
                    commit('setFiltered', true)
                    if (field == "createdAt" || field == "finishAt") {
                        var e = new Date(filter[field])
                        url += "&" + field + "=" + formatDate(e) + " " + e.toLocaleTimeString()
                    } else if (filter[field] || filter[field] === 0) {
                        url += "&" + field + "=" + filter[field]
                    }
                }
            });
            if (state.pagination && state.pagination.sortBy && state.pagination.sortBy.length > 0) {
                url += "&sort_by=" + state.pagination.sortBy[0]
                url += "&sort_desc=" + state.pagination.sortDesc[0]
            }
            axios.get(url).then(items => {
                let avg = buildTimeString(items.data.average)
                commit('setAvgTime', { avg_time: avg })
                commit('setTotalItems', items.data.total)
                commit('setTotalValue', items.data.total_value)
                commit('loading', false)
                commit('setItems', items.data.data)
                commit('setWithdrawalTotals', { withdrawalTotals: items.data.totals })
            }).catch(() => {
                let notification = {
                    show: true,
                    message: "Ocorreu um erro ao buscar os saques",
                    type: "error"
                }
                commit('showNotification', notification)
                commit('loading', false)
            });
        },
        getItemsNoPage: ({ commit, state }) => {
            let url = endPoints.withdrawals24h;
            let filter = state.filter;
            if (!isEmptyFilter(filter)) {
                url += '?page=' + state.pagination.page + '&' + 'limit=' + 999999;

                Object.keys(filter).forEach(function (field) {
                    if (filter[field] !== "" && filter[field] !== undefined) {
                        commit('setFiltered', true)
                        if (field == "createdAt" || field == "finishAt") {
                            var e = new Date(filter[field])
                            url += "&" + field + "=" + formatDate(e) + " " + e.toLocaleTimeString()
                        } else if (filter[field] || filter[field] === 0) {
                            url += "&" + field + "=" + filter[field]
                        }
                    }
                });
                return axios.get(url).then(items => {
                    return items.data.data
                });
            } else {
                return new Promise((resolve) => {
                    resolve([])
                    commit('loading', false)
                    let notification = {
                        show: true,
                        message: "É necessário aplicar algum filtro antes de exportar o relatório",
                        type: "alert"
                    }
                    commit('showNotification', notification)
                })
            }

        },
        checkWithdrawalStatus: ({ commit, dispatch }, payload) => {
            const url = endPoints.withdrawals24h + "/check_status/" + payload.withdrawal_id
            axios.post(url).then(() => {
                let notification = {
                    show: true,
                    message: "Status do saque atualizado com sucesso",
                    type: "success"
                }
                commit('showNotification', notification)
                dispatch('getItems')
            }).catch(() => {
                let notification = {
                    show: true,
                    message: "Ocorreu um erro ao buscar o status do saque. Tente novamente mais tarde",
                    type: "error"
                }
                commit('showNotification', notification)
                dispatch('getItems')
            })
        },
        openHistoryDialog: ({ state, commit }, payload) => {
            let url = endPoints.withdrawals24h + '/history/' + payload.item.id + '?page=1&itemsPerPage=50'
            if (
                state.pagination &&
                state.pagination.sortBy &&
                state.pagination.sortBy.length > 0
            ) {
                url +=
                    "&sort_by=" +
                    (state.pagination.sortBy[0] === "name"
                        ? "id"
                        : state.pagination.sortBy[0]);
                url += "&sort_desc=" + state.pagination.sortDesc[0];
            }

            try {
                axios.get(url).then((items) => {
                    console.log(items.data)
                    commit('showDialogHistory', { show: true })
                    commit('setWithdrawalHistory', { withdrawal: payload.item })
                    commit("setHistoryItems", items.data)
                });
            } catch (error) {
                let notification = {
                    show: true,
                    message: "Ocorreu um erro ao buscar o histórico desse jogador",
                    type: "error"
                }
                commit('showDialogHistory', { show: false })
                commit('showNotification', notification)
            }
        },
        reverseWithdrawal: ({ commit, dispatch, state }) => {
            let url = endPoints.withdrawal
            axios.post(url + "/reverse", state.itemToReverse).then(() => {
                commit('closeReverseDialog')
                let notification = {
                    show: true,
                    message: "Saque extornado com sucesso",
                    type: "success"
                }
                commit('showNotification', notification)
                dispatch('getItems')
            }).catch(() => {
                commit('closeReverseDialog')
                let notification = {
                    show: true,
                    message: "Ocorreu um erro ao extornar o saque. Tente novamente mais tarde",
                    type: "error"
                }
                commit('showNotification', notification)
            })
        },
        refuseWithdrawal: ({ commit, dispatch, state }, payload) => {
            state.editedItem = Object.assign({}, state.withdrawalRefused)
            state.editedIndex = state.items.indexOf(state.withdrawalRefused)
            state.editedItem.status = 7
            state.mode = "EDIT"
            state.screen = payload.screen
            commit('showDialogRefuseWithdrawal', { show: false })
            dispatch('save', { close: false })
        },
        showDeleteDialog: ({ commit }, item) => {
            commit('setDialogDelete', true)
            commit('setItemToDelete', item)
        },
        showReverseDialog: ({ commit }, item) => {
            commit('setDialogReverse', true)
            commit('setItemToReverse', item.withdrawal)
        },
        showContactDialog: ({ commit }, payload) => {
            console.log("PAYLOAD SHOW CONTACT DIALOG", payload)
            var url = endPoints.httpchats + '/contact/' + payload.withdrawal.contact_id
            if (payload.withdrawal.contact_id) {
                axios.get(url).then((response) => {

                    if (response && response.data) {
                        commit('setContactShow', { contact: response.data })
                        commit('setDialogContact', { show: true })
                    } else {
                        let contact = { phone: payload.withdrawal.contact_id, contact_id: payload.withdrawal.contact_id, contact_id2: payload.withdrawal.contact_id, contact_id3: payload.withdrawal.contact_id }
                        console.log(contact)
                        commit('setDialogContact', { show: true })
                        commit('setContactShow', { contact: contact })
                        // let notification = {
                        //     show: true,
                        //     message: "Ocorreu um erro ao buscar o contato!",
                        //     type: "error"
                        // }
                        // commit('showNotification', notification)
                    }
                }).catch(() => {
                    let contact = { phone: payload.withdrawal.contact_id, contact_id: payload.withdrawal.contact_id, contact_id2: payload.withdrawal.contact_id, contact_id3: payload.withdrawal.contact_id }
                    console.log(contact)
                    commit('setDialogContact', { show: true })
                    commit('setContactShow', { contact: contact })
                    // commit('setDialogContact', { show: true })
                    // let notification = {
                    //     show: true,
                    //     message: "Ocorreu um erro ao buscar o contato!",
                    //     type: "error"
                    // }
                    // commit('showNotification', notification)
                })
            } else {
                let notification = {
                    show: true,
                    message: "Campo de contato não foi preenchido!",
                    type: "error"
                }
                commit('showNotification', notification)
            }
        },
        chipsRemoved: ({ dispatch, state }, payload) => {
            state.editedItem = Object.assign({}, payload.withdrawal)
            state.editedIndex = state.items.indexOf(payload.withdrawal)
            state.editedItem.status = 4
            state.screen = payload.screen
            state.mode = "EDIT"
            dispatch('save', { close: false })
        },
        scheduleWithdrawal: ({ dispatch, state }, payload) => {
            state.editedItem = Object.assign({}, payload.withdrawal)
            state.editedIndex = state.items.indexOf(payload.withdrawal)
            state.editedItem.status = 5
            state.screen = payload.screen
            state.mode = "EDIT"
            dispatch('save', { close: false })
        },
        refuseWithdrawalDialog: ({ commit }, payload) => {
            commit('setWithdrawalRefuse', Object.assign({}, payload.withdrawal))
            commit('showDialogRefuseWithdrawal', { show: true })
        },
        clickStartWithdrawal: ({ dispatch }, payload) => {
            dispatch('getProfilePermissions')
            dispatch('checkBlocksAlerts', payload)
        },
        
        handleBlocksAlerts: ({ state, commit, dispatch }, payload) => {
            commit('setWithdrawaBlocksAlertsToHandle', payload)
            if (state.alert_itens.inscriptionAndPixKey == true || state.blocked_itens.length != 0 || state.alert_itens.idAppAndPixKey == true) {
                commit('showDialogBlacklistWithdrawals', { show: true })
            }else {
                if (state.withdrawalBlocksAlertsToHandle.withdrawal.idClub !== null) {
                    axios.get(`${endPoints.clubs}/${state.withdrawalBlocksAlertsToHandle.withdrawal.idClub}`).then((response) => {
                        if (payload.possible_pix) {
                            let forceBank = response.data.forceWithdrawalAccount
                            if (forceBank) {
                                commit('setForceBank', { forceBank: true })
                                state.withdrawalBlocksAlertsToHandle.withdrawal.bank_payment = response.data.idSubaccount
                            }
                            commit('setForceBank', { forceBank: false })
                            dispatch("startWithdrawalPix", { withdrawal: payload.withdrawal, screen: 'withdrawal' })
                        } else {
                            commit('setForceBank', { forceBank: false })
                            dispatch('startWithdrawal', { withdrawal: payload.withdrawal, screen: 'withdrawal' })
                        }
                    }) 
                } else {
                    if (payload.possible_pix) {
                        commit('setForceBank', { forceBank: false })
                        dispatch("startWithdrawalPix", { withdrawal: payload.withdrawal, screen: 'withdrawal' })
                    } else {
                        dispatch('startWithdrawal', { withdrawal: payload.withdrawal, screen: 'withdrawal' })
                    }
                }
               
            }
        },
        startWithdrawalWithBlocksAlerts: async ({ state, commit, dispatch }) => {
            commit('showDialogBlacklistWithdrawals', { show: false })
            if (state.withdrawalBlocksAlertsToHandle.withdrawal.idClub !== null) {
                axios.get(`${endPoints.clubs}/${state.withdrawalBlocksAlertsToHandle.withdrawal.idClub}`).then((response) => {
                    if (state.withdrawalBlocksAlertsToHandle.possible_pix) {
                        let forceBank = response.data.forceWithdrawalAccount
                        let idSubAccount = response.data.idSubaccount
                        if (forceBank) {
                            commit('setForceBank', { forceBank: true })
                            state.withdrawalBlocksAlertsToHandle.withdrawal.bank_payment = idSubAccount
                        }
                        commit('setForceBank', { forceBank: false })
                        dispatch("startWithdrawalPix", { withdrawal: state.withdrawalBlocksAlertsToHandle.withdrawal, screen: 'withdrawal' })
                    } else {
                        commit('setForceBank', { forceBank: false })
                        dispatch('startWithdrawal', { withdrawal: state.withdrawalBlocksAlertsToHandle.withdrawal, screen: 'withdrawal' })
                    }
                })
            } else {
                if (state.withdrawalBlocksAlertsToHandle.possible_pix) {
                    commit('setForceBank', { forceBank: false })
                    dispatch("startWithdrawalPix", { withdrawal: state.withdrawalBlocksAlertsToHandle.withdrawal, screen: 'withdrawal' })
                } else {
                    commit('setForceBank', { forceBank: false })
                    dispatch('startWithdrawal', { withdrawal: state.withdrawalBlocksAlertsToHandle.withdrawal, screen: 'withdrawal' })
                }
            }
        },
        startWithdrawalPix: ({ commit }, payload) => {
            commit('setWithdrawalAutomatic', Object.assign({}, payload.withdrawal))
            commit('showDialogAutomaticWithdrawal', { show: true })
        },
        startWithdrawal: ({ commit, dispatch, state }, payload) => {
            if (state.dialogAutomaticWithdrawal == true) {
                commit('showDialogAutomaticWithdrawal', { show: false })
            }
            state.editedItem = Object.assign({}, payload.withdrawal)
            state.editedItem.bank_payment = 0
            state.editedIndex = state.items.indexOf(payload.withdrawal)
            state.editedItem.status = 1
            state.screen = payload.screen
            state.mode = "EDIT"
            dispatch('save', { close: false })
        },
        payWithdrawalAutomatic: async ({ commit, dispatch }, payload) => {
            commit('loading', true)
            console.log("PAYLOAD WITHDRAWAL", payload)
            if (payload.withdrawal.bank_payment) {
                commit('setBlockedAutomatic', { block: true })
                await axios.post(endPoints.withdrawals24h + "/sendPix/" + payload.withdrawal.id, { idBankSubAccount: payload.withdrawal.bank_payment }).then(() => {
                    commit('loading', false)
                    commit('setWithdrawalAutomatic', {})
                    commit('showDialogAutomaticWithdrawal', { show: false })
                    let notification = {
                        show: true,
                        message: "Saque enviado em processamento!",
                        type: "success"
                    }
                    dispatch('getItems')
                    commit('showNotification', notification)
                    commit('setBlockedAutomatic', { block: false })
                }).catch((response) => {
                    let notification = {
                        show: true,
                        message: response?.response?.data?.message,
                        type: "error"
                    }
                    commit('loading', false)
                    commit('showNotification', notification)
                    commit('setBlockedAutomatic', { block: false })
                })
            } else {
                let notification = {
                    show: true,
                    message: "Banco para pagamento deve ser informado",
                    type: "error"
                }
                commit('showNotification', notification)
                commit('setBlockedAutomatic', { block: false })
            }
        },
        stopWithdrawal: ({ dispatch, state }, payload) => {
            state.editedItem = Object.assign({}, payload.withdrawal)
            state.editedIndex = state.items.indexOf(payload.withdrawal)
            state.editedItem.status = 0
            state.mode = "EDIT"
            state.screen = payload.screen
            dispatch('save', { close: false })
        },
        getProfilePermissions: ({ commit, rootState }) => {
            let url = endPoints.profiles;
            url += '/' + rootState.login.userData.role + '/permissions'
            return axios.get(url).then(items => {
                commit('setProfilePermissions', items.data)
            });
        },
        // checkBlocksAlerts: async ({ state, commit, dispatch }, payload) => {
        //     try {
        //         let filter = {}
        //         filter.id_pppoker = payload.withdrawal.id_pppoker
        //         filter.inscription = payload.withdrawal.inscription
        //         filter.pix_key = payload.withdrawal.pix_key

        //         let url = endPoints.withdrawals_blacklist + '/search_withdrawals_blacklist'
        //         url += '?page=' + state.pagination.page

        //         Object.keys(filter).forEach(function (field) {
        //             if (filter[field] !== "" && filter[field] !== undefined && filter[field] !== null) {
        //                 if (field == "end" || field == "start") {
        //                     var e = new Date(filter[field])
        //                     url += "&" + field + "=" + formatDate(e) + " " + e.toLocaleTimeString() 
        //                 } else {
        //                     url += "&" + field + "=" + filter[field]
        //                 }
        //             }
        //         });

        //         await axios.get(url).then(items => {
        //             if (items.data.data) {
        //                 commit('setBlockedItens', items.data.data)
        //             } else {
        //                 console.log('Nenhum bloqueio encontrado')
        //             }
        //             commit('loading', false)
        //             commit('setFilter', Object.assign({}, defaultFilter))
        //             if (state.blocked_itens.length != 0) {
        //                 let notification = {
        //                     show: true,
        //                     message: "Bloqueios!",
        //                     type: "error"
        //                 }
        //                 commit('showNotification', notification)
        //             }
        //             dispatch('checkAlerts', payload)
        //         });
        //     } catch (error) {
        //         commit('loading', false)
        //         commit('setFilter', Object.assign({}, defaultFilter))
        //         let notification = {
        //             show: true,
        //             message: "Erro ao buscar bloqueios",
        //             type: "error"
        //         }
        //         commit('showNotification', notification)
        //         dispatch('checkAlerts', payload)
        //     }
        // },

        checkBlocksAlerts: async ({ commit, dispatch }, payload) => {
            try {
                let url = endPoints.withdrawals24h + '/check_blocks_and_alerts' + '/' + payload.withdrawal.id

                await axios.get(url).then(items => {
                    if (items.data.alerts) {
                        commit('setAlertItens', items.data.alerts)
                    } else {
                        console.log('Nenhum alerta encontrado')
                    }
                    if (items.data.blocks){
                        commit('setBlockedItens', items.data.blocks)
                    }else{
                        console.log('Nenhum bloqueio encontrado')
                    }
                    dispatch('handleBlocksAlerts', payload)
                });
            } catch (error) {
                commit('loading', false)
                commit('setFilter', Object.assign({}, defaultFilter))
                let notification = {
                    show: true,
                    message: "Erro ao buscar bloqueios/alertas",
                    type: "error"
                }
                commit('showNotification', notification)
                dispatch('handleBlocksAlerts', payload)
            }
        },
        finishWithdrawalInProcess({ commit, dispatch, state }) {
            let url = endPoints.withdrawal + '/finish_in_process/' + state.editedItem.id
            axios.post(url).then(() => {
                let notification = {
                    show: true,
                    message: "Saque finalizado com sucesso",
                    type: "success"
                }

                commit('showNotification', notification)
                dispatch('getItems')
            }).catch(() => {
                let notification = {
                    show: true,
                    message: "Ocorreu um erro ao finalizar o saque",
                    type: "error"
                }
                commit('showNotification', notification)
            })
            dispatch('closeDialogConfirmFinishWithdrawal')
        },
        restart24hService: async ({ commit }) => {
            let url = "https://jenkins.sxgrupo.com.br"
            let token = "kLymmtd5sdek3d2yuOfXIb3arxVvC5D04QHvU"
            let jobName = "job/Reiniciar%20Projetos%20Produ%C3%A7%C3%A3o/job/Containers/job/(Container)%20%20Reiniciar%20Fichas%20Novo%20Backend"
            let headers = {
                method: "POST",
                withCredentials: false,
                "Authorization": 'Basic YnVpbGRfcHJvamVjdHM6MTEyYmZiNDBkZTRlMDRlMTUxYjQ0ZTdhNWJhOWU1ZTM1ZA=='
            }
            await fetch(`${url}/job/${jobName}/build?token=${token}`, { headers }).then(() => {
                let notification = {
                    show: true,
                    message: "Reiniciando serviço",
                    type: "success"
                }
                commit('showNotification', notification)
            }).catch(() => {
                let notification = {
                    show: true,
                    message: "Ocorreu um erro ao reiniciar o serviço",
                    type: "error"
                }
                commit('showNotification', notification)
            })
        },
        save: async ({ dispatch, commit, state }, payload) => {
            if (state.editedItem.bank == "") {
                state.editedItem.bank = null
            }
            commit('loading', true)

            let message = ''
            var url = endPoints.withdrawals24h;
            let func = '';
            if (state.mode == 'ADD') {
                func = axios.post;
                url = endPoints.withdrawals24h;
                message = "Saque adicionado com sucesso."
                state.editedItem.receiptFile = ""
            } else if(state.updatingWithdrawal) {
                func = axios.put;
                url += '/update/' + state.editedItem.id;
                message = "Saque alterado com sucesso."
            } else if (state.editedItem.status == 7) {
                func = axios.put;
                url += '/' + state.editedItem.id + "/refuse";
                message = "Saque recusado com sucesso."
            } else if (state.editedItem.status == 5) {
                func = axios.put;
                url += '/' + state.editedItem.id + "/schedule";
                message = "Saque agendado com sucesso."
            } else if (state.editedItem.status == 4) {
                func = axios.put;
                url += '/' + state.editedItem.id + "/chips_withdraw";
                message = "Saque atualizado com sucesso."
            } else {
                func = axios.put;
                url += '/' + state.editedItem.id + "/finish";
                message = "Saque finalizado com sucesso."
            }

            commit('setBlockRefused', { block: true })

            if (state.mode == 'FINISH') {
                if (state.editedItem.receiptFile && state.editedItem.receiptFile.name) {
                    var file = state.editedItem.receiptFile
                    const toBase64 = file => new Promise((resolve, reject) => {
                        const reader = new FileReader();
                        reader.readAsDataURL(file);
                        reader.onload = () => resolve(reader.result);
                        reader.onerror = error => reject(error);
                    });

                    toBase64(file).then((fileBase64) => {
                        state.editedItem.receiptName = file.name
                        state.editedItem.receipt = fileBase64
                        state.editedItem.receiptFile = ""
                        func(url, state.editedItem).then(
                            () => {
                                if (state.screen != 'messages') {
                                    dispatch('getItems')
                                }
                                let notification = {
                                    show: true,
                                    message: "Saque finalizado com sucesso",
                                    type: "success"
                                }
                                commit('showNotification', notification)

                                if (state.screen != 'messages') {
                                    if (!payload || payload.close != false) {
                                        dispatch('closeDialog')
                                    }
                                } else {
                                    commit('message/showDialogWithdrawal', { show: false })
                                }
                                commit('message/showNotification', notification, { root: true })
                                commit('loading', false)
                            },
                            () => {
                                let notification = {
                                    show: true,
                                    message: "Ocorreu um erro ao finalizar o saque",
                                    type: "error"
                                }
                                if (state.screen == 'messages') {
                                    commit('message/showNotification', notification, { root: true })
                                } else {
                                    commit('showNotification', notification)
                                }
                                commit('loading', false)
                            }
                        );
                    }).catch(error => console.log(error))
                } else {
                    state.editedItem.receiptFile = ""
                }
            } else {
                state.editedItem.receiptFile = ""
            }
            // state.editedItem.contact_id = "558488052311"
            func(url, state.editedItem).then(
                () => {
                    commit('loading', false)
                    let notification = {
                        show: true,
                        message: message,
                        type: "success"
                    }
                    if (state.screen != 'messages') {
                        dispatch('getItems')
                        commit('showNotification', notification)
                        if (!payload || payload.close != false) {
                            dispatch('closeDialog')
                        }
                    } else {
                        commit('showNotification', notification)
                        commit('message/showDialogWithdrawal', { show: false }, { root: true })
                    }
                    commit('setUpdatingWithdrawal', {updatingWithdrawal: false})
                    commit('loading', false)
                    commit('setBlockRefused', { block: false })
                },
                error => {
                    commit('loading', false)
                    let notification = {
                        show: true,
                        message: error?.response?.data?.message,
                        type: "error"
                    }
                    if (notification.message == "Saldo Positivo insuficiente para realizar o saque") {
                        commit('setErrorPositiveBalance', true)
                    }
                    if (state.screen != 'messages') {

                        commit('showNotification', notification)
                    } else {
                        commit('message/showNotification', notification, { root: true })
                    }
                    commit('setBlockRefused', { block: false })
                }
            );
        },
        getPixesByWithdrawal: ({ commit }, payload) => {
            commit('loadingPix', { show: true })
            let url = endPoints.pix + '?withdrawal_id=' + payload.withdrawal.id
            axios.get(url).then(pixes => {
                console.log(pixes.data.data)
                commit('setWithdrawalPix', { items: pixes.data.data })
                commit('loadingPix', { show: false })
            }).catch(() => {
                commit('loadingPix', { show: false })
                let notification = {
                    show: true,
                    message: "Não foi possivel recuperar os pixes do saque",
                    type: "error"
                }
                commit('showNotification', notification)
            })
        },
        showReceipt: ({ commit }, payload) => {
            let url = endPoints.receipt;

            url += '/' + payload.item.id + '?type=0'
            commit('overlay', { show: true })
            axios.get(url).then(r => {
                let file = r.data.file
                let mimetype = file.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0];
                if (mimetype.indexOf("pdf") != -1) {
                    let name = "Comprovante-" + payload.item.id + ".pdf";
                    var a = document.createElement("a");
                    a.href = file;
                    a.download = name;
                    a.click();
                } else {
                    commit('setReceipt', { receipt: r.data.file })
                    commit('setDialogReceipt', { show: true })
                }
                commit('overlay', { show: false })
            }).catch((error) => {
                console.log(error.data)
                let notification = {
                    show: true,
                    message: error?.response?.data?.message ? error?.response?.data.message : "Comprovante não encontrado",
                    type: "error"
                }
                commit('showNotification', notification)
                commit('overlay', { show: false })
            });
        },
        findTransactionPrint: ({ commit }, payload) => {
            commit('loading', { show: true })
            const url = endPoints.orders + '/operation/' + payload.item.id + "?origin=" + 2;
            axios.get(url).then(print => {
                const img = 'data:image/jpeg;base64,' + print.data.transaction_print

                commit('setPrint', { print: img })
                commit('loading', { show: false })
            }).catch(() => {
                commit('loading', { show: false })
                let notification = {
                    show: true,
                    message: "Não foi possível encontrar o print da transação",
                    type: "error"
                }
                commit('showNotification', notification)
            });
        },
    }
}