<template>
  <div>
    <v-snackbar
      :color="notificationType"
      center
      top
      :timeout="2000"
      v-model="showNotification"
    >
      {{ notificationMessage }}
    </v-snackbar>
    <v-list-item class="text-center justify-center elevation-10 agent-data test">
      <v-btn
        class="mx-2"
        fab
        dark
        small
        color="gray"
        @click="returns"
      >
        <v-icon dark>
          mdi-keyboard-return
        </v-icon>
      </v-btn>
      <v-list-item-content>
        <v-list-item-title class="headline">Perfil de Acesso {{profile.name}}</v-list-item-title>
        <v-list-item-subtitle>
          <div>Nome: {{profile.name}}</div>
          <div>Código: {{profile.profile_code}}</div>
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-tabs v-model="tab" class="agent-data elevation-10">
      <v-tab><v-icon color="green" style="margin-right:10px">mdi-account-group</v-icon>Usuários relacionados</v-tab>
      <v-tab class="text-red"><v-icon color="blue" style="margin-right:10px">mdi-menu</v-icon>Menus relacionados</v-tab>
      <!-- <v-tab>Rotas relacionadas</v-tab> -->
      <v-tab><v-icon color="red" style="margin-right:10px">mdi-order-bool-descending-variant</v-icon>Parâmetros relacionadas</v-tab>
    </v-tabs> 
    <v-tabs-items v-model="tab">
      <v-tab-item class="elevation-10">
        <v-card> 
          <v-card-title class="text-center justify-center">
              Usuários relacionados
          </v-card-title>

          <v-btn color="black"  @click="addProfileUser" dark class="add-align-right" small>
              <v-icon>mdi-plus</v-icon>
          </v-btn>
          
          <v-data-table
            :headers="headers"
            :loading="loading"
            :items="users"
            :options.sync="pagination"
            :server-items-length="totalUsers"
            no-data-text="Nenhum usuário encontrado"
            class="elevation-1"
          >
            <template v-slot:[`item.status`]="{ item }">
              <span>{{ getStatusByCode(item.status) }}</span>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
            
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    small
                    class="mr-2"
                    @click="showDeleteDialog(item)"
                  >
                    mdi-delete
                  </v-icon>
                </template>
                <span>Excluir</span>
              </v-tooltip>
            </template>
          </v-data-table>

          <v-dialog :value="dialog" persistent width="600">
            <v-card>
              <v-card-title>
                <span class="headline">{{ this.formTitle() }}</span>
              </v-card-title>

              <v-card-text>
                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-container grid-list-md>
                    <v-layout wrap>
                      <v-flex xs12 sm12 md12>
                        <v-autocomplete
                          clearable
                          deletable-chips
                          v-model="id_user"
                          :no-filter="true"
                          :items="usersAdds"
                          outlined
                          chips
                          small-chips
                          no-data-text="Nenhum usuario encontrado"
                          item-text="name"
                          item-value="id"
                          placeholder="Digite aqui o nome do usuario"
                          label="Usuario"
                          return-object
                          :search-input.sync="searchInput"
                        ></v-autocomplete>
                      </v-flex> 
                    </v-layout>
                  </v-container>
                </v-form>
              </v-card-text>

              <v-card-actions>
                <v-col>
                  <v-btn text @click="closeDialog">{{
                    mode == "VIEW" ? "Voltar" : "Cancelar"
                  }}</v-btn>
                </v-col>
                <v-col class="text-right">
                  <v-btn
                    text
                    type="submit"
                    right
                    v-if="mode == 'EDIT' || mode == 'ADD'"
                    @click="save"
                    >Salvar</v-btn
                  >
                </v-col>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog :value="dialogDelete" persistent width="300">
            <v-card>
              <v-card-title class="headline text-center"
                >Confirmar a remoção?</v-card-title
              >

              <v-card-actions>
                <v-col>
                  <v-btn text @click="closeDeleteDialog()">Cancelar</v-btn>
                </v-col>
                <v-col class="text-right">
                  <v-btn text type="submit" right @click="deleteUser"
                    >Confirmar</v-btn
                  >
                </v-col>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog :value="dialogForce" persistent width="500">
            <v-card>
              <v-card-title class="headline text-center"
                >Esse usuário já está relacionado a outro perfil de acesso. Deseja Alterar seu perfil de acesso?</v-card-title
              >

              <v-card-actions>
                <v-col>
                  <v-btn text @click="closeForceDialog()">Cancelar</v-btn>
                </v-col>
                <v-col class="text-right">
                  <v-btn text type="submit" right @click="saveForce"
                    >Confirmar</v-btn
                  >
                </v-col>
              </v-card-actions>
            </v-card>
          </v-dialog>
            
        </v-card>
      </v-tab-item>

      <v-tab-item class="elevation-10">
        <v-card> 
          <v-card-title class="text-center justify-center">
            Menus relacionados
          </v-card-title>

          <v-treeview
            :items="items" 
            :load-children="getChildrens"
            transition
            activatable
            item-key="items"
          >
            <template v-slot:prepend="{ item }">
              <v-icon>
                {{ item.icon }}
              </v-icon>
            </template>
            <template slot="append" slot-scope="{ item }">
              <v-icon 
                v-if="((item.level == 2 && item.has_relation == 0) || (item.level == 1 && item.has_relation == 0 && item.has_chield == 0)) && profileAdministrator()" 
                @click="addView( item )"
              >
                mdi-eye-off
              </v-icon>
              <v-icon 
                v-if="((item.level == 2 && item.has_relation == 1) || (item.level == 1 && item.has_relation == 1 && item.has_chield == 0)) && profileAdministrator()" 
                color="blue darken-2" 
                @click="removeView( item )"
              >
                mdi-eye
              </v-icon>

              <v-icon 
                v-if="((item.level == 2 && item.has_relation == 0) || (item.level == 1 && item.has_relation == 0 && item.has_chield == 0)) && !profileAdministrator()"
              >
                mdi-eye-off
              </v-icon>
              <v-icon 
                v-if="((item.level == 2 && item.has_relation == 1) || (item.level == 1 && item.has_relation == 1 && item.has_chield == 0)) && !profileAdministrator()" 
                color="blue darken-2"
              >
                mdi-eye
              </v-icon>
            </template>
          </v-treeview>
        </v-card>
      </v-tab-item>

      <!-- <v-tab-item class="elevation-10">
        <v-card> 
          <v-card-title class="text-center justify-center">
              Rotas relacionadas
          </v-card-title>

          <v-btn color="black"  @click="addProfileRoute" dark class="add-align-right" small>
              <v-icon>mdi-plus</v-icon>
          </v-btn>
          
          <v-data-table
            :headers="headersRoutes"
            :loading="loading"
            :items="routes"
            :options.sync="pagination"
            :server-items-length="totalRoutes"
            no-data-text="Nenhuma rota encontrada"
            class="elevation-1"
          >
            <template v-slot:item.type="{ item }">
              <v-chip
                :color="getColor(item)"
                dark
              >
                {{ getType( item.type ) }}
              </v-chip>
            </template>

            <template v-slot:item.actions="{ item }">
            
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    small
                    class="mr-2"
                    @click="showDeleteDialogRoute(item)"
                  >
                    mdi-delete
                  </v-icon>
                </template>
                <span>Excluir</span>
              </v-tooltip>
            </template>
          </v-data-table>

          <v-dialog :value="dialogRoute" persistent width="600">
            <v-card>
              <v-card-title>
                <span>Nova Rota</span>
              </v-card-title>

              <v-card-text>
                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-container grid-list-md>
                    <v-layout wrap>
                      <v-flex xs12 sm12 md12>
                        <v-autocomplete
                          clearable
                          deletable-chips
                          v-model="id_route"
                          :no-filter="true"
                          :items="routesAdds"
                          outlined
                          chips
                          small-chips
                          no-data-text="Nenhuma rota encontrado"
                          item-text="name"
                          item-value="id"
                          placeholder="Digite aqui o nome da rota"
                          label="Rota"
                          return-object
                          :search-input.sync="searchInputRoutes"
                        ></v-autocomplete>
                      </v-flex> 
                    </v-layout>
                  </v-container>
                </v-form>
              </v-card-text>

              <v-card-actions>
                <v-col>
                  <v-btn text @click="closeDialogRoute">{{
                    mode == "VIEW" ? "Voltar" : "Cancelar"
                  }}</v-btn>
                </v-col>
                <v-col class="text-right">
                  <v-btn
                    text
                    type="submit"
                    right
                    v-if="mode == 'EDIT' || mode == 'ADD'"
                    @click="saveRouteA"
                    >Salvar</v-btn
                  >
                </v-col>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog :value="dialogDeleteRoute" persistent width="300">
            <v-card>
              <v-card-title class="headline text-center"
                >Confirmar a remoção?</v-card-title
              >

              <v-card-actions>
                <v-col>
                  <v-btn text @click="closeDeleteDialogRoute()">Cancelar</v-btn>
                </v-col>
                <v-col class="text-right">
                  <v-btn text type="submit" right @click="deleteRoute"
                    >Confirmar</v-btn
                  >
                </v-col>
              </v-card-actions>
            </v-card>
          </v-dialog>
            
        </v-card>
      </v-tab-item> -->

      <v-tab-item class="elevation-10">
        <v-card> 
          <v-card-title class="text-center justify-center">
            Parâmetros relacionados
          </v-card-title>

          <v-list three-line>
            <template>
              <v-list-item>
                <v-icon color="black" style="margin-right:30px">mdi-cash-multiple</v-icon>
                <v-list-item-content>
                  <v-list-item-title>Efetuar Saque</v-list-item-title>
                  <v-list-item-subtitle>Permite que o usuário efetua saques.</v-list-item-subtitle>
                </v-list-item-content>
                <v-icon @click="updateParamWithdrawal(profile.withdrawal)" style="margin-right:30px" color="blue" v-if="getParam(profile.withdrawal)">mdi-checkbox-marked</v-icon>
                <v-icon @click="updateParamWithdrawal(profile.withdrawal)" style="margin-right:30px" v-if="!getParam(profile.withdrawal)">mdi-checkbox-blank-outline</v-icon>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item>
                <v-icon color="black" style="margin-right:30px">mdi-credit-card-outline</v-icon>
                <v-list-item-content>
                  <v-list-item-title>Pagar Despesa</v-list-item-title>
                  <v-list-item-subtitle>Permite que o usuário pague despesas.</v-list-item-subtitle>
                </v-list-item-content>
                <v-icon @click="updateParamExpense(profile.expense)" style="margin-right:30px" color="blue" v-if="getParam(profile.expense)">mdi-checkbox-marked</v-icon>
                <v-icon @click="updateParamExpense(profile.expense)" style="margin-right:30px" v-if="!getParam(profile.expense)">mdi-checkbox-blank-outline</v-icon>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item>
                <v-icon color="black" style="margin-right:30px">mdi-robot-confused</v-icon>
                <v-list-item-content>
                  <v-list-item-title>Ativar Bot</v-list-item-title>
                  <v-list-item-subtitle>Permite que o usuário ative o BOT.</v-list-item-subtitle>
                </v-list-item-content>
                <v-icon @click="updateParamBot(profile.bot)" style="margin-right:30px" color="blue" v-if="getParam(profile.bot)">mdi-checkbox-marked</v-icon>
                <v-icon @click="updateParamBot(profile.bot)" style="margin-right:30px" v-if="!getParam(profile.bot)">mdi-checkbox-blank-outline</v-icon>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item>
                <v-icon color="black" style="margin-right:30px">mdi-book-edit</v-icon>
                <v-list-item-content>
                  <v-list-item-title>Executar Fechamento</v-list-item-title>
                  <v-list-item-subtitle>Permite que o usuário execute o fechamento.</v-list-item-subtitle>
                </v-list-item-content>
                <v-icon @click="updateParamClosure(profile.closure)" style="margin-right:30px" color="blue" v-if="getParam(profile.closure)">mdi-checkbox-marked</v-icon>
                <v-icon @click="updateParamClosure(profile.closure)" style="margin-right:30px" v-if="!getParam(profile.closure)">mdi-checkbox-blank-outline</v-icon>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item>
                <v-icon color="black" style="margin-right:30px">mdi-wechat</v-icon>
                <v-list-item-content>
                  <v-list-item-title>Iniciar Atendimento</v-list-item-title>
                  <v-list-item-subtitle>Faz com que o usuário inicie o atendimento ao enviar uma menasgem dentro do chat.</v-list-item-subtitle>
                </v-list-item-content>
                <v-icon @click="updateParamAtendence(profile.atendence)" style="margin-right:30px" color="blue" v-if="getParam(profile.atendence)">mdi-checkbox-marked</v-icon>
                <v-icon @click="updateParamAtendence(profile.atendence)" style="margin-right:30px" v-if="!getParam(profile.atendence)">mdi-checkbox-blank-outline</v-icon>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item>
                <v-icon color="black" style="margin-right:30px">mdi-poker-chip</v-icon>
                <v-list-item-content>
                  <v-list-item-title>Criar Ordem Manual</v-list-item-title>
                  <v-list-item-subtitle>Permite que o usuário crie uma ordem manualmente.</v-list-item-subtitle>
                </v-list-item-content>
                <v-icon @click="updateParamCreateManualOrder(profile.create_manual_order)" style="margin-right:30px" color="blue" v-if="getParam(profile.create_manual_order)">mdi-checkbox-marked</v-icon>
                <v-icon @click="updateParamCreateManualOrder(profile.create_manual_order)" style="margin-right:30px" v-if="!getParam(profile.create_manual_order)">mdi-checkbox-blank-outline</v-icon>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item>
                <v-icon color="black" style="margin-right:30px">mdi-block-helper</v-icon>
                <v-list-item-content>
                  <v-list-item-title>Efetuar Saque com Bloqueio</v-list-item-title>
                  <v-list-item-subtitle>Permite que o usuário efetue um saque bloqueado na blacklist.</v-list-item-subtitle>
                </v-list-item-content>
                <v-icon @click="updateParamMakeBlockedWithdrawal(profile.make_blocked_withdrawal)" style="margin-right:30px" color="blue" v-if="getParam(profile.make_blocked_withdrawal)">mdi-checkbox-marked</v-icon>
                <v-icon @click="updateParamMakeBlockedWithdrawal(profile.make_blocked_withdrawal)" style="margin-right:30px" v-if="!getParam(profile.make_blocked_withdrawal)">mdi-checkbox-blank-outline</v-icon>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item>
                <v-icon color="black" style="margin-right:30px">mdi-alert-decagram</v-icon>
                <v-list-item-content>
                  <v-list-item-title>Efetuar Saque com Alerta</v-list-item-title>
                  <v-list-item-subtitle>Permite que o usuário efetue um saque com alerta.</v-list-item-subtitle>
                </v-list-item-content>
                <v-icon @click="updateParamMakeAlertWithdrawal(profile.make_alert_withdrawal)" style="margin-right:30px" color="blue" v-if="getParam(profile.make_alert_withdrawal)">mdi-checkbox-marked</v-icon>
                <v-icon @click="updateParamMakeAlertWithdrawal(profile.make_alert_withdrawal)" style="margin-right:30px" v-if="!getParam(profile.make_alert_withdrawal)">mdi-checkbox-blank-outline</v-icon>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item>
                <v-icon color="black" style="margin-right:30px">mdi-chat-remove</v-icon>
                <v-list-item-content>
                  <v-list-item-title>Finalizar Atendimento</v-list-item-title>
                  <v-list-item-subtitle>Permite que o usuário encerre atendimentos de outros usuários</v-list-item-subtitle>
                </v-list-item-content>
                <v-icon @click="updateParamFinishAtendance(profile.finish_atendance)" style="margin-right:30px" color="blue" v-if="getParam(profile.finish_atendance)">mdi-checkbox-marked</v-icon>
                <v-icon @click="updateParamFinishAtendance(profile.finish_atendance)" style="margin-right:30px" v-if="!getParam(profile.finish_atendance)">mdi-checkbox-blank-outline</v-icon>
              </v-list-item>
              <v-list-item>
                <v-icon color="black" style="margin-right:30px">mdi-receipt-text-plus-outline</v-icon>
                <v-list-item-content>
                  <v-list-item-title>Vincular Comprovante</v-list-item-title>
                  <v-list-item-subtitle>Permite que o usuário vincule comprovantes antigos</v-list-item-subtitle>
                </v-list-item-content>
                <v-icon @click="updateParamLinkReceipt(profile.link_receipt)" style="margin-right:30px" color="blue" v-if="getParam(profile.link_receipt)">mdi-checkbox-marked</v-icon>
                <v-icon @click="updateParamLinkReceipt(profile.link_receipt)" style="margin-right:30px" v-if="!getParam(profile.link_receipt)">mdi-checkbox-blank-outline</v-icon>
              </v-list-item>
            </template>
          </v-list>
        </v-card>
      </v-tab-item>

    </v-tabs-items>
  </div>
</template>

<script>
import debounce from '../../util/debounce.js' 
import { mapActions, mapMutations, mapState } from "vuex";
import { mapFields } from "vuex-map-fields"; 
export default {
  name: "Profile",
  computed: {
    items () {
      let dads = []
      this.menus.forEach( dad => {
        if (dad.level == 1) {
          dad.profile_father = parseInt(this.profile_id)
          dads.push(dad)
        }
      });
      return dads
    },  
    ...mapState('login', ['userData']),
    ...mapState("menus", ["menus", "loading", "mode", "notificationMessage", "notificationType"]),
    ...mapState("profile", [
      "profile",
    ]),
    ...mapFields("profile", [
      "showNotification",
      "notificationMessage",
      "notificationType",
    ]),
    ...mapFields("profile", ["overlay"]),
    ...mapState("profile", [
      "dialog",
      "dialogRoute",
      "dialogDelete",
      "dialogDeleteRoute",
      "dialogForce",
      "editedUser",
      "editedRoute",
      "errors",
      "filtered",
      "loading",
      "mode",
      "notificationType",
      "notificationMessage",
      "rowsPerPage",
      "pagination",
      "totalUsers",
      "totalRoutes",
      "users",
      "routes",
      "usersAdds",
      "routesAdds",
    ]),
    ...mapFields("profile", [
      "dialogFilter",
      "editedUser.id_user",
      "editedRoute.id_route",
      "pagination",
      "showNotification",
      // ...
    ]),
  },
  mixins: [debounce],
  mounted() {
    this.getAttendants = this.debounce(this.getAttendants, 500); 
    this.getRoutesAdds = this.debounce(this.getRoutesAdds, 500); 
  },
  created() {
    let profile_id = this.$route.params.profile_id;
    this.profile_id = profile_id;
    this.getProfile({ profile_id: profile_id });
    this.getInstances();
    this.getMenus({ id_profile: parseInt(this.profile_id) });
  },
  data: () => ({
    open: [],
    searchInput: '',
    searchInputRoutes: '',
    headers: [
      {
        text: "Nome",
        align: "left",
        sortable: true,
        value: "name",
      },
      { text: "Status", value: "status", sortable: true },
      { text: "Ações", value: "actions", sortable: false, align: "right"  },
    ],
    headersRoutes: [
      { text: "Nome",  value: "name", sortable: false, align: "left"},
      { text: "Tipo",  value: "type", sortable: false, align: "center" },
      { text: "Tela",  value: "menu_string", sortable: false, align: "center"},
      { text: "Ações",  value: "actions", sortable: false, align: "right" }
    ],
    types: [
      'GET',
      'PUT',
      'POST',
      'PATCH',
      'DELETE'
    ],
    tab: '',
    rules: {
      required: (value) => !!value || "Required.",
      counter: (value) => value.length <= 20 || "Max 20 characters",
      email: (value) => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Invalid e-mail.";
      },
    },
    possible_status: [
      { status: 0, description: "Inativo" },
      { status: 1, description: "Ativo" },
    ],
    sheet_fields: {
      Nome: {
        field: "name",
        callback: (value) => {
          if (value) {
            return value;
          }
        },
      },
      Email: {
        field: "email",
        callback: (value) => {
          if (value) {
            return value;
          }
          return 0;
        },
      },
      Tipo: {
        field: 'type',
        callback: (value) => {
          if(value) {
            let type = [
              'GET',
              'PUT',
              'POST',
              'PATCH',
              'DELETE',
            ]
            return type[value]
          }
          return ''
        }
      },
      "Data de criação": {
        field: "date_created",
        callback: (value) => {
          if (value) {
            let aux = "";
            aux += value[3];
            aux += value[4];
            aux += value[2];
            aux += value[0];
            aux += value[1];
            for (let i = 5; i < value.length; i++) {
              aux += value[i];
            }
            var d = new Date(aux);
            return d.toLocaleDateString();
          }
        },
      },
      status: {
        field: "status",
        callback: (value) => {
          return value ? "Ativo" : "Inativo";
        },
      },
    },
    valid: false,
  }),
  methods: {
    parentLenght(item, items) {
      let count = -1
      items.forEach(unity => {
        if (unity.parent == null) {
          count += 1 
        }
      });
      if (item.order != count) {
        return true 
      } else return false
    },
    ...mapMutations("rake_deals", {
      addRakeDeal: "add",
      editRakeDeal: "edit"
    }),
    ...mapActions("profile", ["getProfile", "goBack"]),
    ...mapActions("instances", ["getInstances"]),
    ...mapActions("menus", ["getChildrens" , "getMenus"]),
    ...mapMutations("profile", ["add", "addRoute", "viewUser", "showFilter"]),
    ...mapActions("profile", [
      "closeDialog",
      "closeDialogRoute",
      "closeDeleteDialog",
      "closeDeleteDialogRoute",
      "closeForceDialog",
      "deleteUser",
      "deleteRoute",
      "saveForce",
      "edit",
      "getAttendants",
      "getRoutesAdds",
      "getItems",
      "getRoutes",
      "getItemsAdds",
      "getItemsNoPage",
      "getUsers",
      "saveUser",
      "saveRouteB",
      "saveMenu",
      "saveMenuFather",
      "removeMenu",
      "removeMenuFather",
      "saveForceUser",
      "showForceDialog",
      "showDeleteDialog",
      "showDeleteDialogRoute",
      "return",
      "updateParam",
    ]),
    filter() {
      this.showFilter({ show: false });
    },
    finishSheet() {
      this.overlay = false;
    },
    formTitle() {
      switch (this.mode) {
        case "ADD":
          return "Novo Usuário";
        case "EDIT":
          return "Editar Usuário";
        default:
          return "Usuário";
      }
    },
    generateSheet() {
      this.overlay = true;
    },
    profileAdministrator() {
      return this.userData.role == 99
    },
    getType(type) {
      return this.types[type]
    },
    getColor(item) {
      if(item.type == 0) {
        return 'blue'
      } else if(item.type == 1) {
        return 'orange'
      } else if(item.type == 2) {
        return 'green'
      } else if(item.type == 3) {
        return 'gray'
      } else if(item.type == 4) {
        return 'red'
      }
    },
    getSheetName() {
      var date = new Date();
      var name =
        "usuarios" +
        date.toLocaleDateString() +
        " " +
        date.toLocaleTimeString();
      return name + ".xls";
    },
    getStatusByCode(role) {
      switch (role) {
        case 0:
          return "Inativo";
        case 1:
          return "Ativo";
      }
    },
    addProfileUser() {
      this.add({ profile_id: this.profile_id });
    },
    addProfileRoute() {
      this.addRoute({ profile_id: this.profile_id });
    },
    save() {
      if (this.editedUser.id_user.id_profile !== null) {
        this.showForceDialog(this.editedUser)
        return 
      }
      this.editedUser.id_user = this.editedUser.id_user.id
      this.$validator.validateAll().then((valid) => {
        if (valid) {
          this.saveUser();
        }
      });
    },
    addView(item) {
      this.$validator.validateAll().then((valid) => {
        if (item.level == 1) {
          if (valid) {
            this.saveMenuFather({ id_menu: item.id, objeto: item, id_profile: parseInt(this.profile_id) });
          }
        } else {
          if (valid) {
            let count = -1
            let correct = 0
            this.items.forEach( fathers => {
              count += 1
              if (fathers.id == item.parent) {
                correct = count
              }
            });
            this.saveMenu({ id_menu: item.id, objeto: this.items[correct], id_profile: parseInt(this.profile_id) });
          }
        }
      });
    },
    saveRouteA() {
      this.editedRoute.id_route = this.editedRoute.id_route.id
      this.$validator.validateAll().then((valid) => {
        if (valid) {
          this.saveRouteB();
        }
      });
    },
    removeView(item) {
      this.$validator.validateAll().then((valid) => {
        if (item.level == 1) {
          if (valid) {
            this.removeMenuFather({ id_relation: item.id_relation, objeto: item });
          }
        } else {
          if (valid) {
            let count = -1
            let correct = 0
            this.items.forEach( fathers => {
              count += 1
              if (fathers.id == item.parent) {
                correct = count
              }
            });
            this.removeMenu({ id_relation: item.id_relation, objeto: this.items[correct] });
          }
        }
      });
    },
    returns() {
      this.return()
    },
    saveForce() {
      this.editedUser.id_user = this.editedUser.id_user.id
      this.$validator.validateAll().then((valid) => {
        if (valid) {
          this.saveForceUser();
        }
      });
    },
    getParam(item) {
      if (item == 1) {
        return true
      } else {
        return false
      }
    },
    updateParamWithdrawal(item) {
      let param = { mode: "withdrawal", bol: 1, profile_id: this.profile.id } 
      if (item == 1) {
        param.bol = 0
      }
      this.updateParam({param: param})
    },
    updateParamExpense(item) {
      let param = { mode: "expense", bol: 1, profile_id: this.profile.id } 
      if (item == 1) {
        param.bol = 0
      }
      this.updateParam({param: param})
    },
    updateParamBot(item) {
      let param = { mode: "bot", bol: 1, profile_id: this.profile.id } 
      if (item == 1) {
        param.bol = 0
      }
      this.updateParam({param: param})
    },
    updateParamClosure(item) {
      let param = { mode: "closure", bol: 1, profile_id: this.profile.id } 
      if (item == 1) {
        param.bol = 0
      }
      this.updateParam({param: param})
    },
    updateParamAtendence(item) {
      let param = { mode: "atendence", bol: 1, profile_id: this.profile.id }
      if (item == 1) {
        param.bol = 0
      }
      this.updateParam({param: param})
    },
    updateParamFinishWithdrawal(item) {
      let param = { mode: "finish_withdrawal", bol: 1, profile_id: this.profile.id }
      if (item == 1) {
        param.bol = 0
      }
      this.updateParam({param: param})
    },
    updateParamCreateManualOrder(item) {
      let param = { mode: "create_manual_order", bol: 1, profile_id: this.profile.id }
      if (item == 1) {
        param.bol = 0
      }
      this.updateParam({param: param})
    },
    updateParamMakeBlockedWithdrawal(item) {
      let param = { mode: "make_blocked_withdrawal", bol: 1, profile_id: this.profile.id }
      if (item == 1) {
        param.bol = 0
      }
      this.updateParam({param: param})
    },
    updateParamMakeAlertWithdrawal(item) {
      let param = { mode: "make_alert_withdrawal", bol: 1, profile_id: this.profile.id }
      if (item == 1) {
        param.bol = 0
      }
      this.updateParam({param: param})
    },
    updateParamFinishAtendance(item) {
      let param = { mode: "finish_atendance", bol: 1, profile_id: this.profile.id }
      if (item == 1) {
        param.bol = 0
      }
      this.updateParam({param: param})
    },
    updateParamLinkReceipt(item) {
      let param = { mode: "link_receipt", bol: 1, profile_id: this.profile.id }
      if (item == 1) {
        param.bol = 0
      }
      this.updateParam({param: param})
    },
  },
  watch: {
    searchInput(val) {
      if (val) {
        var payload = {
          profile_id: this.profile_id,
          search: val,
        };
        this.getAttendants(payload);
      }
    },
    searchInputRoutes(val) {
      if (val) {
        var payload = {
          profile_id: this.profile_id,
          search: val,
        };
        this.getRoutesAdds(payload);
      }
    },
    pagination: {
      handler() {
        this.getItems({ profile_id: this.profile_id });
        this.getRoutes({ profile_id: this.profile_id })
      },
    },
  },
};
</script>

<style>
.test{
  background-color: rgb(250, 221, 166);
}
</style>