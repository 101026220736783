<template>
  <div>
    <v-card>
      <v-card-title>
        <span class="headline text-center justify-center">Filtro</span>

        <v-col class="text-right">
          <v-btn v-if="filtered" color="white" class="ma-2" @click="clearFilter" right small depressed>
            <v-icon color="black">mdi-filter-remove</v-icon>
          </v-btn>
        </v-col>
      </v-card-title>

      <v-card-text>
        <v-form ref="form" lazy-validation>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12 sm6 md6>
                <v-text-field
                  required
                  label="ID"
                  v-model="id"
                  data-vv-name="id"
                  outlined
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-select
                  label="App"
                  :items="yes_no"
                  v-model="is_app"
                  item-text="description"
                  item-value="id"
                  clear-icon="mdi-close"
                  :clearable="true"
                  outlined
                >
                </v-select>
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-text-field
                  required
                  label="CPF/CNPJ"
                  v-model="inscription"
                  data-vv-name="inscription"
                  outlined
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-text-field
                  required
                  label="Nome"
                  v-model="name"
                  data-vv-name="name"
                  outlined
                ></v-text-field>
              </v-flex>
              <!--v-flex xs12 sm6 md6>
                <v-autocomplete
                  label="Banco Pagamento"
                  :items="banks"
                  v-model="bank_payment"
                  :item-text="getTextBank"
                  item-value="id"
                  clear-icon="mdi-close"
                  :clearable="true"
                  outlined
                >
                </v-autocomplete>
              </v-flex-->
              <v-flex xs12 sm6 md6>
                <v-text-field
                  required
                  label="Valor"
                  v-model="value"
                  data-vv-name="value"
                  outlined
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-text-field
                  required
                  label="ID Suprema"
                  v-model="idApp"
                  data-vv-name="idApp"
                  outlined
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-datetime-picker
                  v-model="createdAt"
                  date-format="dd/MM/yyyy"
                  clearText="Limpar"
                  okText="Confirmar"
                  :time-picker-props="timeProps"
                  label="De *"
                  :textFieldProps="{ outlined: true }"
                >
                  <template slot="dateIcon">
                    <v-icon>mdi-calendar</v-icon>
                  </template>
                  <template slot="timeIcon">
                    <v-icon>mdi-clock</v-icon>
                  </template>
                </v-datetime-picker>
                
              </v-flex>

              <v-flex xs12 sm6 md6>
                <v-datetime-picker
                  v-model="finishAt"
                  date-format="dd/MM/yyyy"
                  clearText="Limpar"
                  okText="Confirmar"
                  label="Até *"
                  :time-picker-props="timeProps"
                  :textFieldProps="{outlined: true}"
                >
                  <template slot="dateIcon">
                    <v-icon>mdi-calendar</v-icon>
                  </template>
                  <template slot="timeIcon">
                    <v-icon>mdi-clock</v-icon>
                  </template>
                </v-datetime-picker>
                
              </v-flex>
              <v-flex xs12 sm6 md6>
                  <v-autocomplete
                    label="Plataforma"
                    :items="platforms"
                    v-model="platform"
                    item-text="description"
                    item-value="description"
                    outlined
                  >
                  </v-autocomplete>
                </v-flex>
              <v-flex xs12 sm6 md6>
                <v-autocomplete
                  :readOnly="!platform"
                  label="Clube *"
                  :items="clubsApp.filter(club => club.platform == platform)"
                  v-model="idClub"
                  item-value="id"
                  clear-icon="mdi-close"
                  item-text="clubLabel"
                  :clearable="true"
                  multiple
                  outlined
                ></v-autocomplete>
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-select
                  label="Tipo *"
                  :items="possible_types"
                  v-model="type"
                  item-text="description"
                  item-value="id"
                  clear-icon="mdi-close"
                  :clearable="true"
                  outlined
                >
                </v-select>
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-select
                  label="Retirada *"
                  :items="possible_type_sends"
                  v-model="typeSend"
                  item-text="description"
                  item-value="id"
                  clear-icon="mdi-close"
                  :clearable="true"
                  outlined
                >
                </v-select>
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-select
                  label="Status *"
                  :items="possible_status"
                  v-model="status"
                  item-text="description"
                  item-value="status"
                  clear-icon="mdi-close"
                  :clearable="true"
                  outlined
                  multiple
                >
                </v-select>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-col>
          <v-btn text @click="showFilter({ show: false })">Cancelar</v-btn>
        </v-col>
        <v-col class="text-right">
          <v-btn text type="submit" right @click="filter">Filtrar</v-btn>
        </v-col>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  name: "WithdrawlsFilter",
  computed: {
    ...mapState("clubs", { clubsApp: "clubs", platforms: "platforms"}),
    ...mapGetters("login", ["getInstanceId"]),
    ...mapFields("withdrawal", [
      "filter.id",
      "filter.name",
      "filter.bank",
      "filter.idApp",
      "filter.inscription",
      "filter.value",
      "filter.status",
      "filter.createdAt",
      "filter.finishAt",
      "filter.type",
      "filter.idClub",
      "filter.typeSend",
      "filter.isApp",
      "filter.platform"
      // ...
    ]),
    ...mapState("slots", ["slots"]),
    ...mapState("instances", ["instances"]),
    ...mapState("bank", { banks: "items" }),
    ...mapState("withdrawal", ["filtered"]),
    computedStart() {
      return this.start ? this.start : "";
    },
    computedEnd() {
      return this.date ? this.date : "";
    },
  },
  created() {
    this.getInstances();
  },
  data: () => ({
    possible_status: [
      { status: 0, description: "Pendente" },
      { status: 4, description: "Aguardando Pagamento" },
      { status: 1, description: "Cancelado" },
      { status: 2, description: "Concluido" },
      { status: 3, description: "Estornado" },
      { status: 5, description: "Agendado/Erro" },
      { status: 6, description: "Em Processamento" },
      { status: 7, description: "Recusado"},
      { status: 8, description: "Erro Banco"}
    ],
    possible_types: [
      { id: 0, description: "Fichas" },
      { id: 1, description: "Rakeback" },
      { id: 2, description: "Saldo Positivo" },
      { id: 3, description: "Transferência de Fichas" },
      { id: 4, description: "Ordem Maleta" },
      { id: 5, description: "Devolução" },
      { id: 6, description: "Abate de Anotados" },
    ],
    possible_type_sends: [
      { id: 0, description: "Pendente" },
      { id: 1, description: "Solicitado" },
      { id: 2, description: "Realizado" },
      { id: 3, description: "Manual" },
      { id: 4, description: "Erro Bot" },
      { id: 5, description: "Realizado - PB"},
    ],
    yes_no: [
      { id: 0, description: "Não" },
      { id: 1, description: "Sim" },
    ],
    showStart: false,
    showEnd: false,
    start_formatted: "",
    end_formatted: "",
    timeProps: {
      format: "24hr",
    },
  }),
  methods: {
    ...mapMutations("withdrawal", ["showFilter"]),
    ...mapActions("withdrawal", ["getInstances", "getItems", "clearFilter"]),
    clearStart() {
      this.start = "";
      this.start_formatted = "";
    },
    clearEnd() {
      this.end = "";
      this.end_formatted = "";
    },
    filter() {
      this.getItems();
      this.showFilter({ show: false });
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day.padStart(2, "0")}/${month.padStart(2, "0")}/${year}`;
    },
    getTextBank(item) {
      if (item.account) {
        return item.name + " | " + item.account;
      } else {
        return item.name;
      }
    },
    getTextSlot(item) {
      let platform = ""
      if(item.plataform_id == 0) {
        platform = "Suprema"
      } else if(item.plataform_id == 1) {
        platform = "Pppoker"
      } else if(item.plataform_id == 2) {
        platform = "Cacheta"
      }
      return platform + " | " +item.name
    },
    setStart(date) {
      this.start = date;
      this.showStart = false;
      this.start_formatted = this.formatDate(date);
    },
    setEnd(date) {
      this.end = date;
      this.showEnd = false;
      this.end_formatted = this.formatDate(date);
    },
  },
};
</script>

<style>
</style>