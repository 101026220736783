const deposit = {
    bonus: 0,
    code: null,
    contact_id: '',
    instance_id: null,
    bank: null,
    date: '',
    date_picker: '',
    created_at: '',
    finish_at: null,
    value: '',
    club: '',
    id_pppoker: '',
    group_message: null,
    name: null,
    nick: '',
    observation: '',
    payment_type: null,
    status: 0,
    messages: [],
    receipt: '',
    receipt_file: {},
    type: null,
    id_instance: "",
    sent_id_pppoker: "",
    sent_nick: "",
    sent_id_instance: null,
    pix_identifier: null,
    pix_id: null,
    validation_state: 2,
    type_send: 0,
    free_roll: 0
};

export default deposit