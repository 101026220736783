import axios from 'axios';
// import endPoints from '../../config/endPoints';
import { getField, updateField } from 'vuex-map-fields';
import endPoints from '../../../config/endPoints';
import withdrawal_template from '../../financial/withdrawal/withdrawal_template'
import deposit_template from '../../financial/deposit/deposit_template'
import defaultDeposit from '../../financial/deposit/default_deposit'
import defaultWithdrawal from '../../financial/withdrawal/default_withdrawal'
import defaultFail from '../../financial/fail/default_fail'
import defaultPlayer from '../../players/default_player'
import defaultSale from '../../store/sales/default_sale'
import sale_template from '../../store/sales/sale_template'
import playerTemplate from '../../players/player_template'

import { dataUrlToFile } from '../../../util/Base64'

export default {
    namespaced: true,
    state: {
        contacts: [],
        dialogDeposit: false,
        dialogUtilityOrder: false,
        dialogViewUtilityOrder: false,
        dialogPrint: false,
        dialogWithdrawal: false,
        dialogAnnotated: false,
        dialogFail: false,
        dialogForwardMessage: false,
        dialogPlayer: false,
        dialogVipSale: false,
        editedFail: {},
        forward_contact: '',
        forward_mode: '',
        messageToForward: {},
        notificationTime: 1000,
        notificationMessage: '',
        notificationType: "success",
        showNotification: false,
        instance_id: '',
        textForward: '',
        selected_messages: [],
        ws: {},
        utility_order: {}
    },
    mutations: {
        showDialogWithdrawal: (state, payload) => {
            state.dialogWithdrawal = payload.show
        },
        showDialogAnnotated: (state, payload) => {
            state.dialogAnnotated = payload.show
        },
        showDialogFail: (state, payload) => {
            state.dialogFail = payload.show
            defaultFail.date_picker = new Date()
            state.editedFail = Object.assign({}, defaultFail)
        },
        showDialogDeposit: (state, payload) => {
            state.dialogDeposit = payload.show
            if (payload.show == false) {
                state.selected_messages = []
            }
        },
        showDialogUtilityOrder: (state, payload) => {
            state.dialogUtilityOrder = payload.show
            if (payload.show == false) {
                state.selected_messages = []
            }
        },
        showDialogViewUtilityOrder: (state, payload) => {
            state.dialogViewUtilityOrder = payload.show
            if (payload.show == false) {
                state.utility_order = Object.assign({}, {})
            }
        },
        showDialogVipSale: (state, payload) => {
            state.dialogVipSale = payload.show
        },
        showDialogForwardMessage: (state, payload) => {
            state.messageToForward = Object.assign({}, payload.message),
                state.dialogForwardMessage = payload.show,
                state.textForward = payload.message.content
            state.instance_id = payload.instance_id
            state.forward_mode = payload.forward_mode
            state.forward_contact = payload.forward_contact
        },
        showDialogPlayer: (state, payload) => {
            state.dialogPlayer = payload.show
        },
        showNotification: (state, payload) => {
            state.showNotification = payload.show,
                state.notificationMessage = payload.message,
                state.notificationTime = payload.notificationTime ? payload.notificationTime : 1000
            state.notificationType = payload.type
        },
        setContacts: (state, payload) => {
            state.contacts = payload.contacts
        },
        setSelectedMessages: (state, payload) => {
            state.selected_messages = payload.messages
        },
        showDialogPrint: (state, payload) => state.dialogPrint = payload.show,
        setUtilityOrder: (state, payload) => state.utility_order = payload.item,
        updateField
    },
    getters: {
        getField
    },
    actions: {
        forwardMessage({ commit, state }, payload) {
            if (state.forward_mode == 'RECEIPT') {
                payload.contacts = state.forward_contact
            }
            if (payload.contacts && payload.contacts.length != 0) {
                payload.message = state.messageToForward
                payload.message.content = state.textForward
                payload.message.instance_id = state.instance_id
                axios.post(endPoints.httpchats + "/forwardMessage", payload).then(() => {

                    let notification = {
                        show: true,
                        message: "Mensagem encaminhada com sucesso!",
                        notificationTime: 2500,
                        type: "success"
                    }
                    commit('showNotification', notification)
                }).catch(() => {
                    let notification = {
                        show: true,
                        message: "Ocorreu um erro ao ecaminhar a mensagem!",
                        notificationTime: 2500,
                        type: "error"
                    }
                    commit('showNotification', notification)
                }).finally(() => {
                    let notification = {
                        show: false,
                        notificationTime: 2500,
                        message: ''
                    }
                    commit('showDialogForwardMessage', notification)
                })
            } else {
                let notification = {
                    show: true,
                    message: "Selecione pelo menos um contato!",
                    notificationTime: 2500,
                    type: "error"
                }
                commit('showNotification', notification)
            }
        },
        getContacts({ commit }, payload) {
            if (payload.search) {
                axios.get(endPoints.contact_list + "/search_contacts/" + payload.search).then(response => {
                    var payloadContacts = {
                        contacts: response.data.concat(payload.contacts)
                    }
                    commit('setContacts', payloadContacts)
                });
            } else {
                var payloadContacts = {
                    contacts: payload.contacts
                }
                commit('setContacts', payloadContacts)
            }
        },
        registerFail: ({ commit }) => {

            let fail = Object.assign({}, defaultFail)

            commit('fail/add', { fail: fail, screen: 'messages'}, { root: true })
            commit('showDialogFail', { show: true, mode: 'ADD' })
        },
        createAnnotated: ({ dispatch }, payload) => {
            dispatch('annotateds/getClient', { contact: payload.contact, screen: 'messages' }, { root: true })
            
        },
        createVipSale: ({ dispatch }, payload) => {
            dispatch('vip_sales/createByChat', { contact: payload.contact, screen: 'messages' }, { root: true })
            
        },
        
        createWithdrawal: ({ commit, rootGetters }, payload) => {
            if (payload.message && payload.message.content) {
                var content = payload.message.content
                var lines = content.split("\n")
                let withdrawal = Object.assign({}, defaultWithdrawal)

                withdrawal.idInstance = (payload.idInstance) ? payload.idInstance : null
                withdrawal.phone = payload.phone ? payload.phone : null
                withdrawal.idMessage = payload.message._id

                var hasHeader = false
                if (lines[0].toLowerCase().indexOf("para saque") != -1) {
                    hasHeader = true
                }
                var index = 0;

                lines = lines.filter((l) => l != "")
                lines.forEach((l, i) => {

                    if (!hasHeader) {
                        index = i + 1
                    } else {
                        index = i
                    }

                    if (withdrawal_template[index]) {

                        var template = withdrawal_template[index]
                        var line = l.split(":")
                        var text = (line[1]) ? line[1] : line[0];

                        if (text !== "" && text !== undefined && text !== '' && text != null) {
                            if (text[0] == " ") text = text.replace(" ", "")
                        } else {
                            text = ""
                        }

                        if (template.rule) {
                            if (template.field == 'bank') {
                                let banks = rootGetters["bank/getBanks"]
                                text = template.rule(text, banks)
                            } else if (template.field == 'id_instance') {
                                let clubs = rootGetters["instances/getClubs"]
                                text = template.rule(text, clubs)
                            } 
                            else {
                                text = template.rule(text)
                            }
                        }

                        var regex = /^[a-zA-Z0-9-,.!? ]*$/

                        if (!regex.test(text)) {
                            text = ""
                        }

                        withdrawal[template.field] = text

                    }
                })
                console.log(withdrawal)
                commit('withdrawal/add', { withdrawal: withdrawal, screen: 'messages', contact: payload.contact, message: content, instance_name: payload.instance_name }, { root: true })
                commit('showDialogWithdrawal', { show: true })
            } else {
                commit('withdrawal/add', {}, { root: true })
                commit('showDialogWithdrawal', { show: true })
            }
        },
        createUtilityOrder({ commit, state }){
            const message_id = state.selected_messages[0]._id
            const is_group = state.selected_messages[0].is_group ? 1 : 0
            commit('setSelectedMessages', { messages: [] })
            commit('players_delete/showPlayerDeleteDialog', { show: true, message: { message_id, is_group } }, { root: true })
            commit('showDialogUtilityOrder', { show: true })
        },
        createDeposit({ commit, rootGetters, state }, payload) {
            var deposit = JSON.parse(JSON.stringify(defaultDeposit))
            var message = ""
            deposit.messages = []
            deposit.instance_id = payload.instance_id
            deposit.contact_id = payload.contact_id
            deposit.group_message = (payload.is_group) ? 1 : 0

            state.selected_messages.forEach((m) => {
                deposit.messages.push(m._id)

                if (m.image_base64 != "") {
                    dataUrlToFile(m.image_base64, 'comprovante').then(file => deposit.receipt_file = file)
                } else if (m.document_thumb != "") {
                    dataUrlToFile(m.document_thumb, 'comprovante').then(file => deposit.receipt_file = file)
                } else {
                    var content = m.content
                    message = m.content
                    if (content) {
                        var lines = content.split("\n")
                        var hasHeader = false
                        if (lines[0].toLowerCase().indexOf("formu") != -1) {
                            hasHeader = true
                        }

                        var index = 0;
                        lines = lines.filter((l) => l != "")

                        lines.forEach((l, i) => {

                            if (!hasHeader) {
                                index = i + 1
                            } else {
                                index = i
                            }

                            if (deposit_template[index]) {

                                var template = deposit_template[index]
                                var line = l.split(":")
                                var text = (line[1]) ? line[1] : line[0];
                                if (template.rule) {
                                    if (template.field == 'id_instance') {
                                        let clubs = rootGetters["instances/getClubs"]
                                        text = template.rule(text, clubs)
                                    } else {
                                        text = template.rule(text)
                                    }
                                }
                                if (text !== "" && text !== undefined && text !== '' && text != null) {
                                    if (text[0] == " ") text = text.replace(" ", "")
                                } else {
                                    text = ""
                                }

                                var regex = /^[a-zA-Z0-9-,.!? ]*$/

                                if (!regex.test(text)) {
                                    text = ""
                                }
                                deposit[template.field] = text

                            }
                        })
                    }
                }
            })
            commit('setSelectedMessages', { messages: [] })
            commit('deposit/add', { origin: 'DEPOSIT', deposit: deposit, screen: 'messages', contact: payload.contact, message: message, instance_id: payload.instance_id, instance_name: payload.instance_name }, { root: true })
            commit('showDialogDeposit', { show: true })
        },
        createSale({ commit, state }, payload) {
            var sale = JSON.parse(JSON.stringify(defaultSale))
            var message = ""

            sale.messages = []
            sale.contact_id = payload.contact_id

            state.selected_messages.forEach((m) => {
                sale.messages.push(m._id)

                if (m.image_base64 != "") {
                    dataUrlToFile(m.image_base64, 'comprovante').then(file => sale.receipt_file = file)
                } else if (m.document_thumb != "") {
                    dataUrlToFile(m.document_thumb, 'comprovante').then(file => sale.receipt_file = file)
                } else {
                    var content = m.content
                    message = m.content
                    if (content) {
                        var lines = content.split("\n")
                        var hasHeader = false
                        if (lines[0].toLowerCase().indexOf("formu") != -1) {
                            hasHeader = true
                        }

                        var index = 0;
                        lines = lines.filter((l) => l != "")
                        lines.forEach((l, i) => {

                            if (!hasHeader) {
                                index = i + 1
                            } else {
                                index = i
                            }

                            if (sale_template[index]) {

                                var template = sale_template[index]
                                var line = l.split(":")
                                var text = (line[1]) ? line[1] : line[0];
                                if (template.rule) {
                                    text = template.rule(text)
                                }
                                if (text !== "" && text !== undefined && text !== '' && text != null) {
                                    if (text[0] == " ") text = text.replace(" ", "")
                                } else {
                                    text = ""
                                }

                                var regex = /^[a-zA-Z0-9-,.!? ]*$/

                                if (!regex.test(text)) {
                                    text = ""
                                }
                                sale[template.field] = text

                            }
                        })
                    }
                }
            })
            if (sale.address_cep) {
                var r_cep = sale.address_cep.replace("-")
                if (r_cep.length == 8) {
                    // var cep_json = {}
                    let url = "https://viacep.com.br/ws/" + r_cep + "/json/"

                    var myHeaders = new Headers();
                    var myInit = {
                        method: 'GET',
                        headers: myHeaders,
                        mode: 'cors',
                        cache: 'default'
                    };

                    const myRequest = new Request(url, myInit);
                    fetch(myRequest).then(res => res.json())
                        .then((cep) => {
                            if (cep) {
                                sale.address_neighborhood = cep.bairro
                                sale.address = cep.logradouro
                                sale.address_state = cep.uf
                                sale.address_city = cep.localidade
                            }
                        }).catch((error) => {
                            console.log(error);
                        });
                }
            }
            console.log(sale)
            commit('setSelectedMessages', { messages: [] })
            commit('sales/add', { sale: sale, screen: 'messages', contact: payload.contact, message: message, instance_id: payload.instance_id, instance_name: payload.instance_name }, { root: true })
            commit('showDialogSale', { show: true })
        },
        editWithdrawal: ({ commit }, payload) => {
            if (payload.message && payload.message.content) {
                let withdrawalId = payload.message.withdrawal_id
                let url = endPoints.withdrawal;
                axios.get(url + "/" + withdrawalId).then((w) => {
                    if (w) {
                        let withdrawal = w.data
                        if (withdrawal.status == 0 || withdrawal.status == 5) {
                            withdrawal.club2 = withdrawal.club
                            let content = ''
                            if (payload.message.content) {
                                content = payload.message.content
                            }
                            commit('withdrawal/edit', { withdrawal: withdrawal, screen: 'messages', contact: payload.contact, message: content, instance_name: payload.instance_name }, { root: true })
                            commit('showDialogWithdrawal', { show: true })
                        } else if (withdrawal.status == 1) {
                            let payload = {
                                show: true,
                                message: "Não é possivel editar esse saque pois o mesmo está em andamento!",
                                notificationTime: 3000,
                                type: "error"
                            }
                            commit('showNotification', payload)
                        } else if (withdrawal.status == 2) {
                            let payload = {
                                show: true,
                                message: "Não é possivel editar esse saque pois o mesmo já foi concluído!",
                                notificationTime: 3000,
                                type: "error"
                            }
                            commit('showNotification', payload)
                        } else if (withdrawal.status == 3) {
                            let payload = {
                                show: true,
                                message: "Não é possivel editar esse saque pois o mesmo foi estornado!",
                                notificationTime: 3000,
                                type: "error"
                            }
                            commit('showNotification', payload)
                        } else if (withdrawal.status == 4) {
                            let payload = {
                                show: true,
                                message: "Não é possivel editar esse saque pois as fichas já foram retiradas!",
                                notificationTime: 3000,
                                type: "error"
                            }
                            commit('showNotification', payload)
                        }
                    }
                }).catch(() => {
                    let payload = {
                        show: true,
                        message: "Ocorreu um erro ao buscar o saque. Entre em contato com o TI.",
                        notificationTime: 3000,
                        type: "error"
                    }
                    commit('showNotification', payload)
                });
            }
        },
        editSale: ({ commit, dispatch }, payload) => {
            if (payload.message) {
                let saleId = payload.message.sale_id
                let url = endPoints.sales + "/sale";
                var pMsg = payload.message
                axios.get(url + "/" + saleId).then((s) => {
                    if (s) {
                        let sale = s.data
                        if (sale.status == 0) {
                            let content = ''
                            if (pMsg.content) {
                                content = pMsg.content
                            }
                            dispatch('sales/editItem', { item: sale, screen: 'messages', contact: payload.contact, message: content, instance_name: payload.instance_name }, { root: true })
                            commit('showDialogSale', { show: true })
                        } else {
                            var message = ""
                            var payloadNot = {
                                show: true,
                                message: '',
                                notificationTime: 3000,
                                type: "error"
                            }
                            if (sale.status == 1) {
                                message = "Não é possivel editar essa compra pois ela está em separação!"
                            } else if (sale.status == 2) {
                                message = "Não é possivel editar essa compra pois ela já foi enviado!"
                            } else if (sale.status == 3) {
                                message = "Não é possivel editar essa compra pois ela já foi concluido!"
                            }
                            payloadNot.message = message
                            commit('showNotification', payloadNot)
                        }
                    }
                }).catch((error) => {
                    console.log(error)
                    var payload = {
                        show: true,
                        message: "Ocorreu um erro ao buscar a compra. Entre em contato com o TI.",
                        notificationTime: 3000,
                        type: "error"
                    }
                    commit('showNotification', payload)
                });
            }
        },
        cancelDeposit: ({ commit, dispatch }, payload) => {
            if (payload.message && payload.message.content) {
                let depositId = payload.message.deposit_id
                let url = endPoints.deposits;
                axios.get(url + "/" + depositId).then((d) => {
                    if (d) {
                        let deposit = d.data
                        if (deposit.status == 0 || deposit.status == 5) {
                            dispatch('deposit/showDeleteDialog', deposit, { root: true })
                            commit('deposit/setScreen', { screen: 'messages' }, { root: true })
                        } else if (deposit.status == 1) {
                            let payload = {
                                show: true,
                                message: "Não é possivel cancelar esse depósito pois o mesmo está em andamento!",
                                notificationTime: 3000,
                                type: "error"
                            }
                            commit('showNotification', payload)
                        } else if (deposit.status == 2) {
                            let payload = {
                                show: true,
                                message: "Não é possivel cancelar esse depósito pois o mesmo já foi concluído!",
                                notificationTime: 3000,
                                type: "error"
                            }
                            commit('showNotification', payload)
                        }
                    }
                }).catch(() => {
                    let payload = {
                        show: true,
                        message: "Ocorreu um erro ao buscar o depósito. Entre em contato com o TI.",
                        notificationTime: 3000,
                        type: "error"
                    }
                    commit('showNotification', payload)
                });
            }
        },
        editDeposit: ({ commit }, payload) => {
            if (payload.message && payload.message.deposit_id) {
                let depositId = payload.message.deposit_id
                let url = endPoints.deposits;
                axios.get(url + "/" + depositId).then((w) => {
                    if (w) {
                        let deposit = w.data
                        if (deposit.status == 0 || deposit.status == 5) {
                            let content = ''
                            if (payload.message.content) {
                                content = payload.message.content
                            }
                            commit('deposit/edit', { deposit: deposit, screen: 'messages', contact: payload.contact, message: content, instance_id: payload.instance_id, instance_name: payload.instance_name }, { root: true })
                            commit('showDialogDeposit', { show: true })
                        } else if (deposit.status == 1) {
                            let payload = {
                                show: true,
                                message: "Não é possivel editar esse depósito pois o mesmo está em andamento!",
                                notificationTime: 3000,
                                type: "error"
                            }
                            commit('showNotification', payload)
                        } else if (deposit.status == 2) {
                            let payload = {
                                show: true,
                                message: "Não é possivel editar esse depósito pois o mesmo já foi concluído!",
                                notificationTime: 3000,
                                type: "error"
                            }
                            commit('showNotification', payload)
                        } else if (deposit.status == 3) {
                            let payload = {
                                show: true,
                                message: "Não é possivel editar esse depósito pois o mesmo foi estornado!",
                                notificationTime: 3000,
                                type: "error"
                            }
                            commit('showNotification', payload)
                        }
                    }
                }).catch((error) => {
                    console.log(error)
                    let payload = {
                        show: true,
                        message: "Ocorreu um erro ao buscar o depósito. Entre em contato com o TI.",
                        notificationTime: 3000,
                        type: "error"
                    }
                    commit('showNotification', payload)
                });
            }
        },
        viewDeposit: ({ commit }, payload) => {
            if (payload.message && payload.message.deposit_id) {
                let depositId = payload.message.deposit_id
                let url = endPoints.deposits;
                axios.get(url + "/" + depositId).then((d) => {
                    if (d) {
                        let deposit = d.data
                        commit('deposit/viewItem', { item: deposit, screen: 'messages' }, { root: true })
                        commit('showDialogDeposit', { show: true })
                    }
                }).catch((error) => {
                    console.log(error)
                    let payload = {
                        show: true,
                        message: "Ocorreu um erro ao buscar o depósito. Entre em contato com o TI.",
                        notificationTime: 3000,
                        type: "error"
                    }
                    commit('showNotification', payload)
                });
            }
        },
        viewUtilityOrder: ({ commit }, payload) => {
            if (payload.message && payload.message.utility_order_id) {
                const url = `${endPoints.utilities}/by_order_id/${payload.message.utility_order_id}`
                axios.get(url).then((response) =>{
                    commit('setUtilityOrder', { item: response.data })
                    commit('showDialogViewUtilityOrder', { show: true })
                }).catch(() => {
                    let notification = {
                        show: true,
                        message: "Ocorreu um erro ao buscar a ordem!",
                        notificationTime: 3000,
                        type: "error"
                    }
                    commit('showNotification', notification)
                })
            } else {
                let notification = {
                    show: true,
                    message: "Ocorreu um erro ao buscar a ordem!",
                    notificationTime: 3000,
                    type: "error"
                }
                commit('showNotification', notification)
            }
        },
        cancelWithdrawal: ({ commit, dispatch }, payload) => {
            if (payload.message && payload.message.content) {
                let withdrawalId = payload.message.withdrawal_id
                let url = endPoints.withdrawal;
                axios.get(url + "/" + withdrawalId).then((w) => {
                    if (w) {
                        let withdrawal = w.data
                        if (withdrawal.status == 0 || withdrawal.status == 5) {
                            dispatch('withdrawal/showDeleteDialog', withdrawal, { root: true })
                        } else if (withdrawal.status == 1) {
                            let payload = {
                                show: true,
                                message: "Não é possivel cancelar esse saque pois o mesmo está em andamento!",
                                notificationTime: 3000,
                                type: "error"
                            }
                            commit('showNotification', payload)
                        } else if (withdrawal.status == 2) {
                            let payload = {
                                show: true,
                                message: "Não é possivel cancelar esse saque pois o mesmo já foi concluído!",
                                notificationTime: 3000,
                                type: "error"
                            }
                            commit('showNotification', payload)
                        } else if (withdrawal.status == 3) {
                            let payload = {
                                show: true,
                                message: "Não é possivel editar esse saque pois o mesmo foi estornado!",
                                notificationTime: 3000,
                                type: "error"
                            }
                            commit('showNotification', payload)
                        } else if (withdrawal.status == 4) {
                            let payload = {
                                show: true,
                                message: "Não é possivel editar esse saque pois as fichas já foram retiradas!",
                                notificationTime: 3000,
                                type: "error"
                            }
                            commit('showNotification', payload)
                        }
                    }
                }).catch(() => {
                    let payload = {
                        show: true,
                        message: "Ocorreu um erro ao buscar o saque. Entre em contato com o TI.",
                        notificationTime: 3000,
                        type: "error"
                    }
                    commit('showNotification', payload)
                });
            }
        },
        createPlayer: ({ commit, rootGetters, dispatch }, payload) => {
            if (payload.message && payload.message.content) {
                var content = payload.message.content
                var lines = content.split("\n")
                let player = Object.assign({}, defaultPlayer)

                player.contact_id = payload.message.contact_id ? payload.message.contact_id : null
                player.contact_name = payload.message.contact_name ? payload.message.contact_name : null

                var hasHeader = false
                if (lines[0].toLowerCase().indexOf("de jogador") != -1) {
                    hasHeader = true
                }
                var index = 0;

                lines = lines.filter((line) => line != "")
                lines.forEach((line, i) => {
                    if (!hasHeader) {
                        index = i + 1
                    } else {
                        index = i
                    }

                    if (playerTemplate[index]) {

                        var template = playerTemplate[index]
                        var splittedLine = line.split(":")
                        var text = (splittedLine[1]) ? splittedLine[1].trim() : splittedLine[0].trim();

                        if (template.rule) {
                            let knowByTypes = rootGetters["players/getKnowByTypes"]

                            text = template.rule(text, knowByTypes)
                        }

                        if (template.field === "cpf" && text.length === 11)
                            text = `${text.substring(0, 3)}.${text.substring(3, 6)}.${text.substring(6, 9)}-${text.substring(9, 11)}`

                        player[template.field] = typeof(text) == "string" ? text.trim() : text
                    }

                })

                const selectedContact = { _id: player.contact_id, name: [player.contact_name] }
                commit('showDialogPlayer', { show: true })
                commit('players/setSelectedContact', { selectedContact: selectedContact }, { root: true })
                commit('players/setScreen', { screen: 'messages' }, { root: true })
                commit('players/add', { item: player }, { root: true })
                dispatch('players/validate', { cpf: player.cpf, name: player.name, birth_date: player.birth_date }, { root: true })
            } else {
                let player = Object.assign({}, defaultPlayer)
                player.contact_id = payload.message.contact_id ? payload.message.contact_id : null
                player.contact_name = payload.message.contact_name ? payload.message.contact_name : null

                const selectedContact = { _id: player.contact_id, name: [player.contact_name] }
                commit('showDialogPlayer', { show: true })
                commit('players/setSelectedContact', { selectedContact: selectedContact }, { root: true })
                commit('players/setScreen', { screen: 'messages' }, { root: true })
                commit('players/add', { item: player }, { root: true })
            }
        },
        downloadMessageDocument: ({ commit }, payload) => {
            let url = endPoints.httpchats;
            console.log("Payload: ", payload)
            url += `/get_document_by_message_id/${payload.message_id}/${payload.is_group}`
            axios.get(url).then(r => {
                let file = r.data.file
                
                let name = payload.file_name;
                var a = document.createElement("a");
                a.href = file;
                a.download = name;
                a.click();
                
            }).catch((error) => {
                console.log(error.data)
                let notification = {
                    show: true,
                    message: error?.response?.data?.message ? error?.response?.data.message : "Comprovante não encontrado",
                    type: "error"
                }
                commit('showNotification', notification)
            });
        },
    }
}