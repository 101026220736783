import axios from 'axios';
import { getField, updateField } from 'vuex-map-fields';
import endPoints from '../../config/endPoints';

const defaultItem = {
    id_profile: "",
    id_user: "",
};

const defaultRoute = {
    id_profile: "",
    id_route: "",
};

export default {
    namespaced: true,
    state: {
        
        // Novo
        profilePermissions: {},
        dialogDelete: false,
        dialogForce: false,
        dialogFilter: false,
        dialogDeleteRoute: false,
        editedUser: {},
        editedRoute: {},
        errors: [],
        filter: {
        },
        userToDelete: {},
        routeToDelete: {},
        instances: [],
        loading: false,
        overlay: false,
        pagination: {
            page: 1,
            itemsPerPage: 10
        },
        totalUsers: 0,
        totalRoutes: 0,
        rowsPerPage: 10,
        users: [],
        usersAdds: [],
        routes: [],
        routesAdds: [],

        // Original
        agent: {},
        dialog: false,
        dialogRoute: false,
        contacts: [],
        documents: [],
        itemToDelete: {},
        loading_profile: false,
        mode: 'LIST',
        page: 1,
        profile: {},
        subAgents: [],
        notificationMessage: '',
        notificationType: "success",
        showNotification: false,
        totalIdRelated: 0
    },
    mutations: {
        //Novo
        add: (state, payload) => {
            state.dialog = true
            state.mode = "ADD"
            defaultItem.id_profile = parseInt(payload.profile_id)
            state.editedUser = Object.assign({}, defaultItem)
        },
        addRoute: (state, payload) => {
            state.dialogRoute = true
            state.mode = "ADD"
            defaultRoute.id_profile = parseInt(payload.profile_id)
            state.editedRoute = Object.assign({}, defaultRoute)
        },
        editUser: (state, payload) => {
            state.editedUser = payload.item
            state.dialog = true
            state.mode = "EDIT"
        },
        closeDialog: (state) => {
            state.editedUser = Object.assign({}, defaultItem)
            state.errors = [];
        },
        closeDialogRoute: (state) => {
            state.editedRoute = Object.assign({}, defaultRoute)
            state.errors = [];
        },
        loading: (state, loading) => state.loading = loading,
        overlay: (state, payload) => state.overlay = payload.show,
        setErrorMessage: (state, message) => state.errorMessage = message,
        setMode: (state, mode) => state.mode = mode,
        setUserToDelete: (state, user) => state.userToDelete = user,
        setRouteToDelete: (state, route) => state.routeToDelete = route,
        setEditedUser: (state, user) => state.editedUser = user,
        setUsers: (state, users) => state.users = users,        
        setUsersAdds: (state, payload) => {
            state.usersAdds = payload.usersAdds
        },
        setRoutesAdds: (state, payload) => {
            state.routesAdds = payload.routesAdds
        },       
        setItems: (state, users) => state.users = users,
        setRoutes: (state, routes) => state.routes = routes,
        setInstances: (state, payload) => state.instances = payload.instances,
        setTotalUsers: (state, total) => state.totalUsers = total,
        setTotalItems: (state, total) => state.totalUsers = total,
        setTotalRoutes: (state, total) => state.totalRoutes = total,
        setRowsPerPage: (state, rowsPerPage) => state.rowsPerPage = rowsPerPage,
        showDialog: (state, show) => state.dialog = show,
        showDialogRoute: (state, show) => state.dialogRoute = show,
        setDialogDelete: (state, show) => state.dialogDelete = show,
        setDialogDeleteRoute: (state, show) => state.dialogDeleteRoute = show,
        setDialogForce: (state, show) => state.dialogForce = show,
        showErrorNotification: (state, show) => state.errorNotification = show,
        showFilter: (state, payload) => state.dialogFilter = payload.show,
        showSuccessNotification: (state, show) => state.successNotification = show,
        showLoading: (state, show) => state.loading = show,
        viewUser(state, item) {
            state.editedUser = item
            state.dialog = true
            state.mode = "VIEW"
        },

        // Original
        loading_id_related: (state, payload) => state.loading_id_related = payload.loading,
        loading_profile: (state, payload) => state.loading_profile = payload.loading,
        showNotification: (state, payload) => {
            state.showNotification = payload.show,
                state.notificationMessage = payload.message,
                state.notificationType = payload.type
        },
        setProfile: (state, payload) => state.profile = payload.profile,
        setProfilePermissions: (state, payload) => state.profile = payload.profile,
        updateField
    },
    getters: {
        // Original
        getIdProfile: state => {
            return state.profile.id
        },
        getField,
    },
    actions: {
        getAttendants: ({ commit }, payload) => {
            let url = endPoints.profiles;
            url += '/' + payload.profile_id + '/profile_attendants/' + payload.search
            axios.get(url).then(usersAdds => {
                commit('setUsersAdds', { usersAdds: usersAdds.data })
            });
        },
        getRoutesAdds: ({ commit }, payload) => {
            let url = endPoints.profiles;
            url += '/' + payload.profile_id + '/profile_routesAdds/' + payload.search
            axios.get(url).then(routesAdds => {
                commit('setRoutesAdds', { routesAdds: routesAdds.data })
            });
        },
    
        edit: ({ commit, rootGetters }, payload) => {

            let notification = {
                show: true,
                message: "Você não tem permissão para alterar esse usuario",
                type: "error"
            }

            var role = rootGetters["login/getRole"]
            if (role == 98) {
                var role_chat = [0, 1, 80]
                if (role_chat.indexOf(payload.item.role) == -1) {
                    commit('showNotification', notification)
                    return
                }
            } else if (role == 97) {
                var role_financial = [2]
                if (role_financial.indexOf(payload.item.role) == -1) {
                    commit('showNotification', notification)
                    return
                }
            } else if (role == 96) {
                var role_commercial = [3]
                if (role_commercial.indexOf(payload.item.role) == -1) {
                    commit('showNotification', notification)
                    return
                }
            }
            commit('editUser', payload)
        },
        closeDialog: (({ commit }) => {
            commit('showDialog', false)
            setTimeout(() => {
                commit('closeDialog')
                commit('showErrorNotification', false)
                commit('showSuccessNotification', false)
            }, 300);
        }),
        closeDialogRoute: (({ commit }) => {
            commit('showDialogRoute', false)
            setTimeout(() => {
                commit('closeDialogRoute')
                commit('showErrorNotification', false)
                commit('showSuccessNotification', false)
            }, 300);
        }),
        closeDeleteDialog: ({ commit }) => {
            commit('setDialogDelete', false)
            commit('setUserToDelete', {})
        },
        closeDeleteDialogRoute: ({ commit }) => {
            commit('setDialogDeleteRoute', false)
            commit('setRouteToDelete', {})
        },
        closeForceDialog: ({ commit }) => {
            commit('setDialogForce', false)
            commit('setEditedUser', {})
        },
        getInstances: ({ commit }) => {
            let url = endPoints.instances;
            url += '?page=' + 1 + '&' + 'itemsPerPage=' + 30;
            axios.get(url).then(instances => {
                commit('setInstances', { instances: instances.data.data })
            });
        },
        getItems: ({ commit, state }, payload) => {
            commit('loading', true)
            let url = endPoints.profiles + "/" + payload.profile_id + "/users";
            url += '?page=' + state.pagination.page + '&' + 'itemsPerPage=' + state.pagination.itemsPerPage;
            axios.get(url).then(items => {
                commit('setTotalItems', items.data.total)
                commit('loading', false)
                commit('setItems', items.data.data)
            });
        },
        getRoutes: ({ commit, state }, payload) => {
            commit('loading', true)
            let url = endPoints.profiles + "/" + payload.profile_id + "/routes";
            url += '?page=' + state.pagination.page + '&' + 'itemsPerPage=' + state.pagination.itemsPerPage;
            axios.get(url).then(routes => {
                commit('setTotalRoutes', routes.data.total)
                commit('loading', false)
                commit('setRoutes', routes.data.data)
            });
        },
        getItemsNoPage: (payload) => {
            let url = endPoints.profiles + "/" + payload.profile_id;
            url += '?page=' + '1' + '&' + 'itemsPerPage=' + '99999';
            return axios.get(url).then(items => {
                return items.data.data
            });
        },
        showForceDialog: ({ commit }, item) => {
            commit('setDialogForce', true)
            commit('setEditedUser', item)
        },
        showDeleteDialog: ({ commit }, item) => {
            commit('setDialogDelete', true)
            commit('setUserToDelete', item)
        },
        showDeleteDialogRoute: ({ commit }, item) => {
            commit('setDialogDeleteRoute', true)
            commit('setRouteToDelete', item)
        },
        deleteUser: ({ dispatch, commit, state }) => {
            commit('loading', true)
            let profile = state.userToDelete.id_profile
            axios.delete(endPoints.profiles + "/deleteUser/" + state.userToDelete.id, state.userToDelete).then(() => {
                dispatch('closeDeleteDialog')
                let notification = {
                    show: true,
                    message: "Usuário removido com sucesso.",
                    type: "success"
                }
                commit('showNotification', notification)              
                return dispatch('getItems', { profile_id: profile } );
            });
        },
        deleteRoute: ({ dispatch, commit, state }) => {
            commit('loading', true)
            let profile = state.profile.id
            axios.delete(endPoints.profiles + "/deleteRoute/" + state.routeToDelete.id, state.routeToDelete).then(() => {
                dispatch('closeDeleteDialogRoute')
                let notification = {
                    show: true,
                    message: "Rota removida com sucesso.",
                    type: "success"
                }
                commit('showNotification', notification)              
                return dispatch('getRoutes', { profile_id: profile } );
            });
        },
        saveUser: ({ dispatch, commit, state }) => {
            var url = endPoints.profiles + '/user';
            axios.post(url, state.editedUser).then(
                (response) => {
                    dispatch('closeForceDialog')
                    dispatch('getItems', { profile_id: response.data.id_profile } )
                    let notification = {
                        show: true,
                        message: "Usuário relacionado com sucesso",
                        type: "success"
                    }
                    commit('showNotification', notification)
                    commit('showDialog', false)
                },
                error => {
                    let notification = {
                        show: true,
                        message: error.response.data.message,
                        type: "error"
                    }
                    commit('showNotification', notification)
                }
            );
        },
        saveRouteB: ({ dispatch, commit, state }) => {
            var url = endPoints.profiles + '/route';
            axios.post(url, state.editedRoute).then(
                (response) => {
                    dispatch('closeDialogRoute')
                    dispatch('getRoutes', { profile_id: response.data.id_profile } )
                    let notification = {
                        show: true,
                        message: "Rota relacionada com sucesso",
                        type: "success"
                    }
                    commit('showNotification', notification)
                    commit('showDialog', false)
                },
                error => {
                    let notification = {
                        show: true,
                        message: error.response.data.message,
                        type: "error"
                    }
                    commit('showNotification', notification)
                }
            );
        },
        saveMenu: ({ dispatch, commit }, payload) => {
            var url = endPoints.profiles + '/menu';
            axios.post(url, payload).then(
                () => {
                let notification = {
                    show: true,
                    message: "Menu adicionado com sucesso",
                    type: "success"
                }
                commit('showNotification', notification)
                dispatch('menus/getChildrens', payload.objeto, {root:true} )
                dispatch('getRoutes', { profile_id: payload.objeto.profile_father } )
                },
                error => {
                    let notification = {
                        show: true,
                        message: error.response.data.message,
                        type: "error"
                    }
                    commit('showNotification', notification)
                }
            );
        },
        saveMenuFather: ({ dispatch, commit }, payload) => {
            var url = endPoints.profiles + '/menu_father';
            axios.post(url, payload).then(
                () => {
                let notification = {
                    show: true,
                    message: "Menu adicionado com sucesso",
                    type: "success"
                }
                commit('showNotification', notification)
                dispatch('menus/getChildrens', payload.objeto, {root:true} )
                dispatch('menus/getMenus', { id_profile: payload.objeto.profile_father }, {root:true} )
                },
                error => {
                    let notification = {
                        show: true,
                        message: error.response.data.message,
                        type: "error"
                    }
                    commit('showNotification', notification)
                }
            );
        },
        removeMenu: ({ dispatch, commit }, payload) => {
            var url = endPoints.profiles + '/deleteMenu/' + payload.id_relation;
            axios.delete(url).then(
                () => {
                    let notification = {
                        show: true,
                        message: "Menu removido com sucesso",
                        type: "success"
                    }
                    commit('showNotification', notification)
                    dispatch('menus/getChildrens', payload.objeto, {root:true} )
                    dispatch('getRoutes', { profile_id: payload.objeto.profile_father } )
                },
                error => {
                    let notification = {
                        show: true,
                        message: error.response.data.message,
                        type: "error"
                    }
                    commit('showNotification', notification)
                }
            );
        },
        removeMenuFather: ({ dispatch, commit }, payload) => {
            var url = endPoints.profiles + '/deleteMenuFather/' + payload.id_relation;
            axios.delete(url).then(
                () => {
                    let notification = {
                        show: true,
                        message: "Menu removido com sucesso",
                        type: "success"
                    }
                    commit('showNotification', notification)
                    dispatch('menus/getChildrens', payload.objeto, {root:true} )
                    dispatch('menus/getMenus', { id_profile: payload.objeto.profile_father }, {root:true} )
                },
                error => {
                    let notification = {
                        show: true,
                        message: error.response.data.message,
                        type: "error"
                    }
                    commit('showNotification', notification)
                }
            );
        },
        saveForceUser: ({ dispatch, commit, state }) => {
            var url = endPoints.profiles + '/updateUser/' + state.editedUser.id_user;
            axios.put(url, state.editedUser).then(
                (response) => {
                    dispatch('closeForceDialog')
                    dispatch('getItems', { profile_id: response.data.id_profile } )
                    let notification = {
                        show: true,
                        message: "Usuário salvo com sucesso",
                        type: "success"
                    }
                    commit('showNotification', notification)
                    commit('showDialog', false)
                },
                error => {
                    let notification = {
                        show: true,
                        message: error.response.data.message,
                        type: "error"
                    }
                    commit('showNotification', notification)
                }
            );
        },
        getProfile: ({ commit }, payload) => {
            commit('loading_profile', { loading: true })
            let url = endPoints.profiles + "/" + payload.profile_id;

            axios.get(url).then(response => {
                commit('setProfile', { profile: response.data })
                commit('loading_profile', { loading: false })
            });
        },
        goBack: ({ dispatch }) => {
            dispatch('menu/openLink', '/agents', { root: true })
        },
        return({ dispatch }) {
            dispatch('menu/openLink', '/profiles', { root: true })
        },
        updateParam: ({ dispatch, commit }, payload) => {
            let url = endPoints.profiles + "/updateParam/" + payload.param.profile_id;
            let item = {};
            if (payload.param.mode == "expense") {
                item.expense = payload.param.bol
            } else if (payload.param.mode == "bot") {
                item.bot = payload.param.bol
            } else if (payload.param.mode == "closure") {
                item.closure = payload.param.bol
            } else if (payload.param.mode == "atendence") {
                item.atendence = payload.param.bol
            } else if (payload.param.mode == "withdrawal") {
                item.withdrawal = payload.param.bol
            } else if (payload.param.mode == "finish_withdrawal") {
                item.finish_withdrawal = payload.param.bol
            } else if (payload.param.mode == "create_manual_order") {
                item.create_manual_order = payload.param.bol
            } else if (payload.param.mode == "make_blocked_withdrawal") {
                item.make_blocked_withdrawal = payload.param.bol
            } else if (payload.param.mode == "make_alert_withdrawal") {
                item.make_alert_withdrawal = payload.param.bol
            }else if (payload.param.mode == "finish_atendance") {
                item.finish_atendance = payload.param.bol
            }else if(payload.param.mode == "link_receipt"){
                item.link_receipt = payload.param.bol
            }
            axios.put(url, item).then(
                () => {
                    dispatch('getProfile', { profile_id: payload.param.profile_id, resetPage: true })
                    let notification = {
                        show: true,
                        message: "Parâmetro alterado com sucesso",
                        type: "success"
                    }
                    commit('showNotification', notification)
                },
                error => {
                    let notification = {
                        show: true,
                        message: error.response.data.message,
                        type: "error"
                    }
                    commit('showNotification', notification)
                }
            );

        },
        getProfilePermissions: ({ commit }, payload) => {
            commit('loading_profile', { loading: true })
            let url = endPoints.profiles + "/" + payload.user.role + "/permissions";

            axios.get(url).then(response => {
                commit('setProfilePermissions', { profile: response.data })
                commit('loading_profile', { loading: false })
            });
        },
    }
}
