const ruleClub = (text, clubs) => {
    let int = parseInt(text)
    let club = ''
    let findById = (c) => {
        return c.id_pppoker && c.id_pppoker.indexOf(text) != -1
    }
    let findByName = (c) => {
        return c.name && c.name.toLowerCase().indexOf(text) != -1
    }
    if (!isNaN(int) && int != "" && int != undefined) {
        club = clubs.find(findById)
    } else {
        club = clubs.find(findByName)
    }
    if (club && club != '' && club.id) {
        return club.id_instance
    }
}

const rulePix = (text) => {
    return text.replace(/[^\d]/g, "")
}

const deposit_template = {
    1: { field: 'nick' },
    2: { field: 'id_pppoker' },
    3: { field: 'id_instance', rule: ruleClub },
    4: { field: 'pix_identifier', rule: rulePix }
};

export default deposit_template