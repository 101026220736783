var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{on:{"drop":function($event){$event.preventDefault();return _vm.addDropFile.apply(null, arguments)},"dragover":function($event){$event.preventDefault();},"paste":_vm.onPaste}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Saques")]),(_vm.screen == 'messages')?_c('v-expansion-panels',[_c('v-expansion-panel',[_c('v-expansion-panel-header',{staticClass:"message-with-header"},[_vm._v("Mensagem De: "+_vm._s(_vm.contact)+" | "+_vm._s(_vm.instance_name))]),_c('v-expansion-panel-content',{staticClass:"message-with-content"},[_c('div',{staticClass:"message-withdrawal",domProps:{"innerHTML":_vm._s(_vm.messageTreated)}})])],1)],1):_vm._e(),_c('v-spacer'),(_vm.loading)?_c('v-progress-circular',{staticClass:"text-right",attrs:{"indeterminate":"","color":"primary","size":"24"}}):_vm._e()],1),_c('v-card-text',[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-container',{attrs:{"grid-list-md":""}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":"","sm6":"","md6":""}},[_c('v-select',{attrs:{"clear-icon":"","label":"Tipo *","items":_vm.possible_types.filter(
                                            (type) =>
                                                (type != 2 && type != 6) ||
                                                this.phone,
                                        ),"readonly":this.mode != 'ADD',"item-text":"description","item-value":"id","outlined":"","rules":[_vm.rules.required]},on:{"change":_vm.typeChange},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}})],1),_c('v-flex',{attrs:{"xs12":"","sm6":"","md6":""}},[_c('v-text-field',{attrs:{"required":"","label":"CPF/CNPJ *","readonly":this.mode != 'ADD',"rules":[_vm.rules.required, _vm.rules.cpfcnpj],"outlined":""},model:{value:(_vm.inscription),callback:function ($$v) {_vm.inscription=$$v},expression:"inscription"}})],1),_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":""}},[_c('v-text-field',{attrs:{"required":"","label":"Nome *","readonly":this.mode != 'ADD',"rules":[
                                        _vm.rules.required,
                                        _vm.rules.max_length(this.name, 255),
                                    ],"outlined":""},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1),(this.mode == 'FINISH')?_c('v-flex',{attrs:{"xs12":"","sm6":"","md6":""}},[_c('v-select',{directives:[{name:"validate",rawName:"v-validate",value:(
                                        'required:Banco de Origem,bank_payment'
                                    ),expression:"\n                                        'required:Banco de Origem,bank_payment'\n                                    "}],attrs:{"label":"Banco Origem*","items":_vm.banks,"readonly":this.mode != 'FINISH',"error-messages":_vm.errors.collect('bank_payment'),"item-text":_vm.getTextBank,"item-value":"id","data-vv-name":"bank_payment","outlined":""},model:{value:(_vm.bank_payment),callback:function ($$v) {_vm.bank_payment=$$v},expression:"bank_payment"}})],1):_vm._e(),(_vm.type == 0 || _vm.type == 3 || _vm.type == 6)?_c('v-flex',{attrs:{"xs12":"","sm6":"","md6":""}},[_c('v-autocomplete',{attrs:{"label":_vm.getPlayerLabel('Plataforma'),"items":_vm.platforms,"readonly":this.mode != 'ADD',"item-text":"description","item-value":"description","outlined":"","rules":[_vm.rules.required]},on:{"input":_vm.updatePlatformName},model:{value:(_vm.platform_name),callback:function ($$v) {_vm.platform_name=$$v},expression:"platform_name"}})],1):_vm._e(),(_vm.type == 0 || _vm.type == 3 || _vm.type == 6)?_c('v-flex',{attrs:{"xs12":"","sm6":"","md6":""}},[_c('v-autocomplete',{attrs:{"label":_vm.getPlayerLabel('Clube'),"items":this.clubsApp.filter(
                                            (club) =>
                                                club.platform ==
                                                _vm.platform_name_data || club.platform == _vm.platform_name,
                                        ),"readonly":this.mode != 'ADD' ||
                                        !_vm.platform_name_data,"item-text":"clubLabel","item-value":"id","outlined":"","rules":[_vm.rules.required]},model:{value:(_vm.idClub),callback:function ($$v) {_vm.idClub=$$v},expression:"idClub"}})],1):_vm._e(),(_vm.type == 0 || _vm.type == 3 || _vm.type == 6)?_c('v-flex',{attrs:{"xs12":"","sm6":"","md6":""}},[_c('v-text-field',{attrs:{"required":"","label":_vm.getPlayerLabel('ID App'),"readonly":this.mode != 'ADD',"rules":[_vm.rules.required],"data-vv-name":"idApp","outlined":""},model:{value:(_vm.idApp),callback:function ($$v) {_vm.idApp=$$v},expression:"idApp"}})],1):_vm._e(),_c('v-flex',{attrs:{"xs12":"","sm6":"","md6":""}},[_c('v-text-field',{attrs:{"label":"Valor *","readonly":this.mode != 'ADD',"error-messages":_vm.errors.collect('value'),"rules":[
                                        _vm.rules.required,
                                        _vm.rules.decimal(2),
                                        _vm.rules.minValue(this.value, 0),
                                    ],"outlined":""},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}})],1),(this.typeWithdrawalShowPix(_vm.type))?_c('v-flex',{attrs:{"xs12":"","sm6":"","md6":""}},[_c('v-text-field',{attrs:{"label":"Chave Pix *","readonly":this.mode != 'ADD',"rules":this.typeWithdrawalShowPix(_vm.type)
                                            ? [
                                                  _vm.rules.required,
                                                  _vm.rules.max_length(
                                                      this.pixKey,
                                                      255,
                                                  ),
                                              ]
                                            : [],"outlined":""},model:{value:(_vm.pixKey),callback:function ($$v) {_vm.pixKey=$$v},expression:"pixKey"}})],1):_vm._e(),(_vm.type == 3)?_c('v-flex',{attrs:{"xs12":"","sm6":"","md6":""}},[_c('v-autocomplete',{attrs:{"label":"Plataforma (Envio) *","items":_vm.platforms,"readonly":this.mode != 'ADD',"item-text":"description","item-value":"description","outlined":"","rules":[_vm.rules.required]},model:{value:(_vm.platformSend),callback:function ($$v) {_vm.platformSend=$$v},expression:"platformSend"}})],1):_vm._e(),(_vm.type == 3)?_c('v-flex',{attrs:{"xs12":"","sm6":"","md6":""}},[_c('v-autocomplete',{attrs:{"label":"Clube (Envio) *","items":_vm.clubsApp.filter(
                                            (club) =>
                                                club.platform ==
                                                _vm.platformSend,
                                        ),"readonly":this.mode != 'ADD',"item-text":"clubLabel","item-value":"id","outlined":""},model:{value:(_vm.transferIdClub),callback:function ($$v) {_vm.transferIdClub=$$v},expression:"transferIdClub"}})],1):_vm._e(),(_vm.type == 3)?_c('v-flex',{attrs:{"xs12":"","sm6":"","md6":""}},[_c('v-text-field',{attrs:{"required":_vm.type == 3,"label":"ID App (Envio) *","readonly":this.mode != 'ADD',"rules":[_vm.rules.required],"outlined":""},model:{value:(_vm.transferIdApp),callback:function ($$v) {_vm.transferIdApp=$$v},expression:"transferIdApp"}})],1):_vm._e(),(this.mode != 'ADD')?_c('v-flex',{attrs:{"xs12":"","sm4":"","md4":""}},[_c('v-select',{attrs:{"clear-icon":"","label":"Status *","items":_vm.possible_status,"readonly":true,"error-messages":_vm.errors.collect('status'),"v-validate":'required:Status,status',"item-text":"description","item-value":"status","data-vv-name":"status","outlined":""},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})],1):_vm._e(),_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":""}},[_c('v-text-field',{attrs:{"label":_vm.type == 5
                                            ? 'Comentários *'
                                            : 'Comentários',"readonly":this.mode == 'VIEW',"outlined":"","rules":_vm.type == 5
                                            ? [
                                                  _vm.rules.required,
                                                  _vm.rules.max_length(
                                                      this.comments,
                                                      255,
                                                  ),
                                              ]
                                            : []},model:{value:(_vm.comments),callback:function ($$v) {_vm.comments=$$v},expression:"comments"}})],1),_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":""}},[_c('v-text-field',{attrs:{"label":"Observações","readonly":this.mode == 'VIEW',"error-messages":_vm.errors.collect('observation'),"data-vv-name":"observation","outlined":""},model:{value:(_vm.observation),callback:function ($$v) {_vm.observation=$$v},expression:"observation"}})],1),(
                                    _vm.mode == 'VIEW' && _vm.screen == 'withdrawal'
                                )?_c('v-expansion-panels',[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v("Auditoria")]),_c('v-expansion-panel-content',[_c('v-row',[_c('v-flex',{attrs:{"xs12":"","sm4":"","md4":""}},[_c('v-text-field',{attrs:{"required":"","label":"Criado em","readonly":true,"outlined":"","value":_vm.getDate(
                                                        this.createdAt,
                                                    )}})],1),(_vm.payedAt)?_c('v-flex',{attrs:{"xs12":"","sm4":"","md4":""}},[_c('v-text-field',{attrs:{"required":"","label":"Pago em","readonly":true,"outlined":"","value":_vm.getDate(
                                                        this.payedAt,
                                                    )}})],1):_vm._e(),(_vm.finishAt)?_c('v-flex',{attrs:{"xs12":"","sm4":"","md4":""}},[_c('v-text-field',{attrs:{"required":"","label":"Finalizado em","readonly":true,"outlined":"","value":_vm.getDate(
                                                        this.finishAt,
                                                    )}})],1):_vm._e(),(_vm.refusedAt)?_c('v-flex',{attrs:{"xs12":"","sm4":"","md4":""}},[_c('v-text-field',{attrs:{"required":"","label":"Recusado em","readonly":true,"outlined":"","value":_vm.getDate(
                                                        this.refusedAt,
                                                    )}})],1):_vm._e()],1),_c('v-row',[(_vm.createdBy)?_c('v-flex',{attrs:{"xs12":"","sm4":"","md4":""}},[_c('v-select',{attrs:{"required":"","label":"Criado Por","readonly":true,"outlined":"","items":_vm.users,"item-text":"name","item-value":"id"},model:{value:(_vm.createdBy),callback:function ($$v) {_vm.createdBy=$$v},expression:"createdBy"}})],1):_vm._e(),(_vm.payedUser)?_c('v-flex',{attrs:{"xs12":"","sm4":"","md4":""}},[_c('v-select',{attrs:{"required":"","label":"Pago Por","readonly":true,"outlined":"","items":_vm.users,"item-text":"name","item-value":"id"},model:{value:(_vm.payedUser),callback:function ($$v) {_vm.payedUser=$$v},expression:"payedUser"}})],1):_vm._e(),(_vm.finishUser)?_c('v-flex',{attrs:{"xs12":"","sm4":"","md4":""}},[_c('v-select',{attrs:{"required":"","label":"Finalizado Por","readonly":true,"outlined":"","items":_vm.users,"item-text":"name","item-value":"id"},model:{value:(_vm.finishUser),callback:function ($$v) {_vm.finishUser=$$v},expression:"finishUser"}})],1):_vm._e(),(_vm.refusedBy)?_c('v-flex',{attrs:{"xs12":"","sm4":"","md4":""}},[_c('v-select',{attrs:{"required":"","label":"Recusado Por","readonly":true,"outlined":"","items":_vm.users,"item-text":"name","item-value":"id"},model:{value:(_vm.refusedBy),callback:function ($$v) {_vm.refusedBy=$$v},expression:"refusedBy"}})],1):_vm._e()],1)],1)],1)],1):_vm._e(),(
                                    _vm.mode == 'VIEW' && _vm.screen == 'withdrawal'
                                )?_c('v-expansion-panels',[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v("Pix Relacionados")]),_c('v-expansion-panel-content',[_c('v-data-table',{staticClass:"elevation-6 ma-2 table-margin px-6 pt-3 row-pointer",attrs:{"headers":_vm.headersPix,"loading":_vm.loadingPix,"items":_vm.withdrawalPix,"no-data-text":"Nenhum pix encontrado","footer-props":{
                                                'items-per-page-options': [
                                                    10, 20, 50, 100,
                                                ],
                                                'items-per-page-text':
                                                    'Itens por página',
                                            }},scopedSlots:_vm._u([{key:`item.date`,fn:function({
                                                    item,
                                                }){return [_c('span',[_vm._v(_vm._s(_vm.getDate(item.date)))])]}},{key:`item.value`,fn:function({
                                                    item,
                                                }){return [_c('span',[_vm._v(_vm._s(item.value.toLocaleString( 'pt-BR', { style: 'currency', currency: 'BRL', }, )))])]}},{key:`item.type`,fn:function({
                                                    item,
                                                }){return [_c('span',[_vm._v(_vm._s(item.type == 1 ? 'Entrada' : 'Saída'))])]}},{key:`item.status`,fn:function({
                                                    item,
                                                }){return [_c('v-chip',{attrs:{"text-color":"white","color":item.status == 1
                                                            ? 'black'
                                                            : item.status ==
                                                              2
                                                            ? 'green'
                                                            : 'red'}},[_c('span',[_vm._v(_vm._s(_vm.getStatusPix( item.status, )))])])]}}],null,true)})],1)],1)],1):_vm._e(),_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":""}},[(this.mode == 'FINISH')?_c('v-file-input',{attrs:{"label":"Comprovante","outlined":"","dense":""},model:{value:(_vm.receipt_file),callback:function ($$v) {_vm.receipt_file=$$v},expression:"receipt_file"}}):_vm._e()],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-col',[_c('v-btn',{attrs:{"text":""},on:{"click":_vm.closeDialog}},[_vm._v(_vm._s(_vm.mode == 'VIEW' ? 'Voltar' : 'Cancelar'))])],1),_c('v-col',{},[(_vm.showSave() && _vm.errorPositiveBalance)?_c('v-btn',{attrs:{"text":"","type":"submit","right":""},on:{"click":function($event){return _vm.saveWithdrawal(true)}}},[_vm._v("Forçar Saldo Positivo")]):_vm._e()],1),_c('v-col',{staticClass:"text-right"},[(_vm.showSave())?_c('v-btn',{attrs:{"text":"","type":"submit","right":""},on:{"click":function($event){return _vm.saveWithdrawal(false)}}},[_vm._v("Salvar")]):_vm._e()],1)],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }