<template>
    <div>
        <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>

        <v-snackbar
            :color="notificationType"
            center
            top
            :timeout="2000"
            v-model="showNotification"
        >
            {{ notificationMessage }}
        </v-snackbar>
        <v-toolbar flat class="elevation-5">
            <div>
                <v-toolbar-title>Ordens BOT</v-toolbar-title>
            </div>
            <v-spacer></v-spacer>
            <v-btn
                color="black"
                dark
                class="ma-2"
                @click="showBotDialog({ show: true })"
                small
                ><v-icon>mdi-robot</v-icon></v-btn
            >
            <div class="button-toolbar">
                <v-btn
                    :v-show="profileAccess()"
                    color="black"
                    dark
                    class="ma-2"
                    @click="add({ show: true })"
                    small
                    ><v-icon>mdi-plus-outline</v-icon>Criar Ordem Manual</v-btn
                >

                <v-btn
                    color="black"
                    dark
                    class="ma-2"
                    @click="showFilter({ show: true })"
                    small
                    ><v-icon>mdi-filter</v-icon></v-btn
                >
                <v-btn color="black" dark class="ma-2" @click="reload" small
                    ><v-icon>mdi-refresh</v-icon></v-btn
                >
            </div>
        </v-toolbar>

        <v-data-table
            :headers="headers"
            :loading="loading"
            :items="items"
            :item-class="getClassColor"
            :options.sync="pagination"
            :server-items-length="totalItems"
            :footer-props="{
                'items-per-page-options': [10, 20, 50, 100],
                'items-per-page-text': 'Itens por página',
                'show-first-last-page': true,
                'page-text': `Página ${pagination.page} de ${
                    parseInt(totalItems / pagination.itemsPerPage) + 1
                }`,
            }"
            no-data-text="Nenhuma ordem encontrada"
            class="elevation-10 table-margin"
        >
            <template v-slot:[`item.type`]="{ item }">
                <v-chip
                    :color="getColorTypeOrder(item.type)"
                    class="ma-2"
                    text-color="white"
                    label
                    >{{ getTypeOrder(item.type) }}</v-chip
                >
            </template>
            <template v-slot:[`item.value`]="{ item }">
                <span>{{
                    item.value.toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                    })
                }}</span>
            </template>
            <template v-slot:[`item.status`]="{ item }">
                <v-chip
                    :color="getColorStatus(item.status)"
                    class="ma-2"
                    text-color="white"
                    label
                    >{{ getStatus(item) }}</v-chip
                >
            </template>
            <template v-slot:[`item.finished_by`]="{ item }">
                <v-chip
                    :color="getColorIsBot(item)"
                    class="ma-2"
                    text-color="white"
                    label
                    >{{ getIsBot(item) }}</v-chip
                >
            </template>
            <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            small
                            v-on="on"
                            v-bind="attrs"
                            class="mr-2"
                            @click="view({ item: item })"
                        >
                            mdi-information
                        </v-icon>
                    </template>
                    <span>Verificar Conta</span>
                </v-tooltip>
                <v-tooltip bottom v-if="item.status == 2 && item.finish_at">
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            small
                            v-on="on"
                            v-bind="attrs"
                            class="mr-2"
                            @click="findTransactionPrint({ item })"
                        >
                            mdi-image-area
                        </v-icon>
                    </template>
                    <span>Print</span>
                </v-tooltip>
                <v-tooltip bottom v-if="item.status == 16">
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            small
                            v-on="on"
                            v-bind="attrs"
                            class="mr-2"
                            @click="checkOrderTimeOut({ item: item })"
                        >
                            mdi-folder-search
                        </v-icon>
                    </template>
                    <span>Verificar Status</span>
                </v-tooltip>
                <v-tooltip bottom v-if="showButton(item) == true">
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            small
                            v-on="on"
                            v-bind="attrs"
                            class="mr-2"
                            @click="setOrderFinish({ item: item })"
                        >
                            mdi-check-bold
                        </v-icon>
                    </template>
                    <span>Finalizar Ordem</span>
                </v-tooltip>
                <v-tooltip bottom v-if="item.status != 0">
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            small
                            v-on="on"
                            v-bind="attrs"
                            class="mr-2"
                            @click="forceNotification({ item: item })"
                        >
                            mdi-bell-check-outline
                        </v-icon>
                    </template>
                    <span>Forçar Notificação</span>
                </v-tooltip>
                <v-tooltip bottom v-if="item.origin == 16 && item.type == 1 && item.id_double_check == null && (item.status == 7 || item.status == 2)">
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            small
                            v-on="on"
                            v-bind="attrs"
                            class="mr-2"
                            @click="checkAccount({ item: item })"
                        >
                            mdi-account-check
                        </v-icon>
                    </template>
                    <span>Verificar Conta</span>
                </v-tooltip>
                <v-tooltip bottom v-if="item.status == 16">
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            small
                            v-on="on"
                            v-bind="attrs"
                            class="mr-2"
                            @click="updateTimeoutOrder({ ...item, status: 0 })"
                        >
                            mdi-backup-restore
                        </v-icon>
                    </template>
                    <span>Alterar para Aguardando</span>
                </v-tooltip>
            </template>
            <template v-slot:[`item.date`]="{ item }">
                <span>{{ getDate(item.created_at) }}</span>
            </template>
            <template v-slot:[`item.finish_at`]="{ item }">
                <span>{{ getDate(item.finish_at) }}</span>
            </template>
        </v-data-table>

        <v-dialog
            :value="dialogFilter"
            width="600"
            @keydown.esc="showFilter({ show: false })"
            @click:outside="showFilter({ show: false })"
        >
            <bots-filter></bots-filter>
        </v-dialog>

        <v-dialog
            :value="botDialog"
            width="600"
            @click:outside="showBotDialog({ show: false })"
        >
            <bots-grid></bots-grid>
        </v-dialog>
        <v-dialog
            :value="manualOrderDialog"
            width="600"
            @click:outside="showManualOrderDialog({ show: false })"
        >
            <bots-form></bots-form>
        </v-dialog>
        <v-dialog
            :value="dialogBotsForm"
            width="1000"
            @click:outside="showBotsFormDialog({ show: false })"
        >
            <bots-form></bots-form>
        </v-dialog>

        <v-dialog
            :value="dialogPrint"
            width="600"
            @click:outside="showDialogPrint({ show: false })"
        >
            <v-card height="350px">
                <v-img :src="print" contain></v-img>
            </v-card>
        </v-dialog>

        <v-dialog
            :value="dialogFinish"
            width="450"
            @click:outside="closeDialogFinish()"
        >
            <v-card height="350px" @dragover.prevent @paste="onPaste">
                <v-card-title>
                    <span class="headline">Finalizar Ordem </span>
                </v-card-title>
                <v-card-text>
                    <v-form ref="form" lazy-validation>
                        <v-container grid-list-md>
                            <v-layout wrap>
                                <v-flex xs12 sm12 md12>
                                    <v-file-input
                                        v-model="transaction_print"
                                        label="Print *"
                                        @paste="onPaste"
                                        :rules="rulesFields"
                                        outlined
                                    ></v-file-input>
                                </v-flex>
                                <v-flex xs12 sm12 md12>
                                    <v-text-field
                                        label="Gestor *"
                                        v-model="manager"
                                        :readonly="this.mode == 'VIEW'"
                                        :rules="rulesFields"
                                        outlined
                                    ></v-text-field>
                                </v-flex>
                            </v-layout>
                        </v-container>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-col>
                        <v-btn text @click="closeDialogFinish()"
                            >Cancelar</v-btn
                        >
                    </v-col>
                    <v-col class="text-right">
                        <v-btn
                            text
                            type="submit"
                            right
                            @click="finish({ item: finishItem })"
                            >Finalizar</v-btn
                        >
                    </v-col>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import BotsFilter from './BotsFilter'
import BotsGrid from './BotsGrid'
import BotsForm from './BotsForm'

export default {
    name: 'Bots',
    components: {
        BotsFilter,
        BotsGrid,
        BotsForm,
    },
    computed: {
        ...mapState('login', ['userData']),
        ...mapState('bots', [
            'dialogFilter',
            'botDialog',
            'dialogBotsForm',
            'manualOrderDialog',
            'item',
            'loading',
            'mode',
            'notificationType',
            'notificationMessage',
            'rowsPerPage',
            'totalItems',
            'items',
            'finishItem',
            'selectedItem',
            'managers',
            'overlay',
            'dialogPrint',
            'dialogFinish',
            'print',
        ]),
        ...mapFields('bots', [
            'pagination',
            'finishItem.transaction_print',
            'finishItem.manager',
            'showNotification',
        ]),
    },
    created() {
        this.getItems({ resetPage: false })
        this.getSlots({
            resetPage: false,
            noPages: true,
            onlyIfNotSearched: true,
            inactive: false
        })
        this.watchOrders()
        this.getPossiblesOrigins()
        this.$validator.extend('required', {
            getMessage: (field, [label]) => label + ' deve ser preenchido',
            validate: (_, [, field]) => {
                if (
                    (this[field] || this[field] === 0) &&
                    this[field] !== '' &&
                    this[field] !== null
                ) {
                    return true
                } else {
                    return false
                }
            },
        })
    },
    methods: {
        ...mapActions('slots', ['getSlots']),
        ...mapMutations('bots', [
            "add",
            'showFilter',
            'showBotDialog',
            'showBotsFormDialog',
            'showManualOrderDialog',
            'showDialogPrint',
            'closeDialogFinish',
            'view'
        ]),
        ...mapActions('bots', [
            'updateTimeoutOrder',
            'checkOrderTimeOut',
            'closeDialog',
            'getItems',
            'setPrintCopy',
            'setOrderFinish',
            'checkAccount',
            'getBots',
            'findTransactionPrint',
            'finishOrder',
            'forceNotification',
            'getPossiblesOrigins'
        ]),
        profileAccess() {
            return this.userData.role >= 80
        },
        showButton(item) {
            if (item.status != 2 && item.status != 7) {
                return true
            } else {
                return false
            }
        },
        onPaste(e) {
            if (e.clipboardData && e.clipboardData.items.length != 0) {
                var file = e.clipboardData.items[0]
                var file_data = file.getAsFile()
                this.finishItem.transaction_print = file_data
            }
        },
        finish(payload) {
            this.$validator.validateAll().then((valid) => {
                if (valid) {
                    this.finishOrder({ item: payload.item })
                }
            })
        },
        reload() {
            this.getItems({ resetPage: false })
            this.getBots()
        },

        watchOrders() {
            if (this.mode == 'LIST') {
                this.getItems({ resetPage: false })
            }
            if (this.$router) {
                if (this.$router.history.current.name == 'bots') {
                    setTimeout(() => {
                        this.watchOrders()
                    }, 450000) //VOLTAR PRO ORIGINAL ANTES DE COMMITAR
                }
            }
        },
        isMobile() {
            if (
                /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                    navigator.userAgent,
                )
            ) {
                return true
            }
            return false
        },
        getClassColor(item) {
            if (item.id_bot == 8) {
                if (item.tries > 1) {
                    return 'row-yellow-schedule'
                } else {
                    return 'row-green'
                }
            } else if (item.tries > 1) {
                return 'row-orange'
            } else {
                return ''
            }
        },
        getStatus(item) {
            switch (item.status) {
                case 0:
                    return 'Aguardando'
                case 1:
                    return 'Em andamento'
                case 2:
                    return 'Concluído'
                case 3:
                    return 'Saldo Insuficiente'
                case 4:
                    return 'Clube sem Saldo'
                case 5:
                    return 'Id Inválido'
                case 6:
                    return 'Bot Inativo'
                case 7:
                    return 'Concluído - Sem Print'
                case 8:
                    return 'Jogador não é agente'
                case 9:
                    return 'Recusado (Outros)'
                case 10:
                    return 'Erro Confirmação'
                case 11:
                    return 'Valor inválido'
                case 12:
                    return 'Ip inválido'
                case 13:
                    return 'Ip não informado'
                case 15:
                    return 'Ordem duplicada'
                case 16:
                    return 'Timeout'
                case 17:
                    return 'Erro no Print'
                case 18:
                    return 'Limite de Requisições'
            }
        },
        getColorIsBot(item) {
            if(item.status == 2 || item.status == 7) {
                if (item.finished_by == 0) {
                    return 'green'
                } else {
                    return 'red'
                }
            } else {
                return 'grey'
            }

        },
        getIsBot(item) {
            if(item.status == 2 || item.status == 7){
                if (item.finished_by == 0) {
                    return 'Sim'
                } else {
                    return 'Não'
                }
            } else {
                return '-'
            }
           
        },
        getColorStatus(status) {
            if (status == 0) {
                return 'cyan'
            } else if (status == 1) {
                return 'red'
            } else if (status == 2 || status == 7) {
                return 'green'
            } else if (status == 16) {
                return '#d3bd02'
            } else if (status >= 3) {
                return 'red'
            }
        },
        getColorTypeOrder(status) {
            if (status == 0 || status == 2) {
                return 'blue'
            } else if (status == 1 || status == 3) {
                return 'green'
            }
        },
        getValue(value) {
            return value != null ? value : '-----'
        },
        getDate(date) {
            if (date != null && date != '') {
                var d = new Date(date)
                return d.toLocaleDateString() + ' ' + d.toLocaleTimeString()
            } else {
                return '--------'
            }
        },
        getTypeOrder(type) {
            switch (type) {
                case 0:
                    return 'Saque'
                case 1:
                    return 'Depósito'
                case 2:
                    return 'Saque Maleta'
                case 3:
                    return 'Depósito Maleta'
            }
        },
    },
    data: () => ({
        rulesFields: [(value) => !!value || "Campo obrigatório"],
        expanded: [],
        headers: [
            {
                text: 'ID',
                align: 'left',
                value: 'id',
                sortable: false,
            },
            {
                text: 'Tipo',
                align: 'left',
                value: 'type',
                sortable: false,
                width: '5%',
            },
            {
                text: 'Origem',
                align: 'left',
                value: 'bot_origin',
                sortable: false,
            },
            {
                text: 'ID Externo',
                align: 'left',
                value: 'external_id',
                sortable: false,
            },
            {
                text: 'BOT',
                align: 'left',
                value: 'id_bot',
                sortable: false,
            },
            {
                text: 'Clube',
                value: 'slot_name',
                sortable: false,
            },
            { text: 'ID Suprema', value: 'player_id', sortable: false },
            { text: 'Valor', value: 'value', sortable: false },
            { text: 'Início', value: 'date', sortable: false },
            { text: 'Finalizado em', value: 'finish_at', sortable: false },
            { text: 'Status', value: 'status', sortable: false },
            { text: 'Automático', value: 'finished_by', sortable: false },
            { text: 'Ações', value: 'actions', sortable: false },
        ],
        valid: false,
        timeProps: {
            format: '24hr',
        },
    }),
    watch: {
        pagination: {
            handler() {
                this.getItems({ resetPage: false })
            },
        },
    },
}
</script>

<style>
</style>
