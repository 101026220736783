import axios from 'axios';
import { getField, updateField } from 'vuex-map-fields';
import endPoints from '../../config/endPoints';
import router from '../../config/router';
import { isLocalHost, isHomolog, completeUrl } from '../../config/endPoints';

const localStorageItem = 'access_token_sx'

export default {
    namespaced: true,
    state: {
        default_password: 0,
        maxRequests:3,
        accessToken: '',
        otp_key: '',
        accessToken_expire: '',
        errorNotification: false,
        errorMessage: '',
        newMessage: false,
        showNotification: false,
        notificationType: '',
        notificationMessage: '',
        user: {},
        user_ip_front: '',
        userData: {},
        authenticationStep: false,
        qrCodeAuthenticator: '',
        qrCodeAuthenticatorShow: false,
    },
    mutations: {
        setAccessToken: (state, accessToken) => {
            state.accessToken = accessToken
            console.log('setei o token')
        },
        setAccessTokenExpire: (state, expire) => state.accessToken_expire = expire,
        setErrorMessage: (state, message) => state.errorMessage = message,
        setUserData: (state, userData) => state.userData = userData,
        showErrorNotification: (state, show) => state.errorNotification = show,
        showNotification: (state, payload) => {
            state.showNotification = payload.show,
                state.notificationMessage = payload.message,
                state.notificationType = payload.type
        },
        setDefaultPassword: (state, payload) => {
            state.default_password = payload;
        },
        setUserIp: (state, payload) => {  
            state.user_ip_front = payload.ip;
        },
        updateField,
        setAuthenticationStep: (state, payload) => state.authenticationStep = payload.show,
        setOtpKey: (state, payload) => state.otp_key = payload.key,
        decreaseRequests: (state) => state.maxRequests = state.maxRequests-1,
        resetRequests: (state) => state.maxRequests = 3,
        setQrCodeAuthenticatorShow: (state, payload) => state.qrCodeAuthenticatorShow = payload.show,
        setQrCodeAuthenticator: (state, payload) => {
            state.qrCodeAuthenticator = payload.qrCode
        }
    },
    getters: {
        getField,
        getUserId: state => {
            return state.userData.id
        },
        getUserName: state => {
            return state.userData.name
        },
        getAccessToken: state => {
            return state.accessToken
        },
        getRole: state => {
            return state.userData.role
        },
        getInstanceId: state => {
            return state.userData.instance_id
        },
        isLogged: state => {
            console.log("dados", state.accessToken, localStorage.getItem(localStorageItem))
            return !!(state.accessToken) || !!(localStorage.getItem(localStorageItem))
        },
        getDefaultPassword: state => {
            return (state.default_password)
        },
        getAuthenticationStep: state => {
            return (state.authenticationStep)
        },
        checkProfileAccessManager: state => { 
            return state.userData.role >= 90
        },
        checkProfileAccessAdmin: state => {
            return state.userData.role >= 99
        },
        checkProfileAcessWithdrawalLeader: state => {
            let profilesLeader = [2, 3, 7, 9, 80, 98, 99]
            return profilesLeader.includes(state.userData.role)
        },
    },

    actions: {
        getUser: ({ commit, dispatch }, accessToken) => {
            let url = endPoints.users + "/user/" + accessToken.user_id
 
            axios.get(url).then((user) => {
                dispatch('menu/buildMenu', {}, {root: true})
                commit('setUserData', user.data)
                commit('setAccessTokenExpire', accessToken.expire)
                localStorage.setItem(localStorageItem, accessToken.access_token)
                if (user.data.default_password == 1) {
                    commit("setDefaultPassword", user.data.default_password)
                    router.push("/profile_edit")
                } else if (router.history.current.path == "/login") {
                    commit("setDefaultPassword", user.data.default_password)
                    router.push("/")
                } else if(router.history.current.path == '/loginsx') {
                    router.push("/")
                }
                dispatch('profile/getProfilePermissions', { user: user.data }, { root: true })
            })
        },
        checkUser: ({ commit, state, dispatch }) => {
            let loginData = state.user
            loginData.grant_type = "password"
            loginData.user_ip_front = state.user_ip_front

            axios.post(endPoints.login, state.user).then((response) => {
                let qr_code = response.data.qr_code
                if (response.data.authenticator_configured == false) {
                    commit('setQrCodeAuthenticatorShow', {show: true})
                    commit('setQrCodeAuthenticator', {qrCode: 'data:image/jpeg;base64,' + qr_code.base64})
                } else {
                    commit('setQrCodeAuthenticatorShow', {show: false})
                }
                commit('setOtpKey',{key: response.data.key})
                commit('setAuthenticationStep',{show: true, key: response.key})
                if(isLocalHost(window.location.hostname)){
                    commit('setAccessToken', response.data.access_token.access_token)
                    dispatch('getUser', response.data.access_token)
                }
            }, (error) => {
                let message = error?.response?.data?.message
                let notification = {}
                if (message && message == "Usuário não possui perfil de acesso associado") {
                    notification = {
                        show: true,
                        message: "Usuário não possui perfil de acesso associados",
                        type: "error"
                    }
                    commit('showNotification', notification) 
                } else if (isLocalHost(window.location.hostname)){
                    notification = {
                        show: true,
                        message: "Oi dev, vamos ser mais inteligente?. Ta passando email ou senha inválida",
                        type: "error"
                    }
                } else {
                    notification = {
                        show: true,
                        message: "Usuário ou senha inválidos",
                        type: "error"
                    }
                    commit('showNotification', notification)
                }
            })
        },
        checkAuthentication:({commit, state, dispatch}, token) => {
            let loginData = state.user
            loginData.client_secret = token
            loginData.client_key = state.otp_key
            loginData.user_ip_front = state.user_ip_front

            axios.post(endPoints.login + "/check_auth", state.user).then((response) => {
           
                commit('setAccessToken', response.data.access_token.access_token)
                dispatch('getUser', response.data.access_token) 
                commit('setAuthenticationStep',{show: false})
            }, (error) => {
                let message = error?.response?.data?.message
                console.log(message)
                if (message && message == "Max password tries exceeded"){
                    commit('setAuthenticationStep',{show: false})
                }
                commit('decreaseRequests');
                let notification = {
                    show: true,
                    message: "Token informado errado ou expirado. Tentativas restantes = " + state.maxRequests,
                    type: "error"
                }
                if (state.maxRequests == 0){
                    notification = {
                        show: true,
                        message: "Token informado errado muitas vezes. Insira novamente seu login e senha",
                        type: "error"
                    }
                    commit('resetRequests');
                }
                commit('showNotification', notification)
            }) 
        },
        logout: ({ commit, state }) => {
            axios.delete(endPoints.login + "/" + state.accessToken).then(() => {
                let oldAccessToken = state.accessToken
                commit('setAccessToken', '')
                localStorage.removeItem(localStorageItem)
                setTimeout(() => {
                    if(isLocalHost(window.location.host) || isHomolog(window.location.host)) {
                        let loginUrlRedirect = `${endPoints.homolog_external_login_url}/logout?accessToken=${oldAccessToken}&redirectUrl=${completeUrl}/loginsx`
                        window.location.href = loginUrlRedirect
                    } else {
                        let loginUrlRedirect = `${endPoints.external_login_url}/logout?accessToken=${oldAccessToken}&redirectUrl=${completeUrl}/loginsx`
                        window.location.href = loginUrlRedirect
                    }
                }, 2000)
            }).catch(() => {
                let oldAccessToken = state.accessToken
                commit('setAccessToken', '')
                localStorage.removeItem(localStorageItem)
                setTimeout(() => {
                    if(isLocalHost(window.location.host) || isHomolog(window.location.host)) {
                        let loginUrlRedirect = `${endPoints.homolog_external_login_url}/logout?accessToken=${oldAccessToken}&redirectUrl=${completeUrl}/loginsx`
                        window.location.href = loginUrlRedirect
                    } else {

                        let loginUrlRedirect = `${endPoints.external_login_url}/logout?accessToken=${oldAccessToken}&redirectUrl=${completeUrl}/loginsx`
                        window.location.href = loginUrlRedirect
                    }
                }, 2000)
            });

        },
        getAccessTokenFromLocalStorage: ({ dispatch, state }) => {
            
            if (!state.accessToken) {
                let token = localStorage.getItem(localStorageItem)
                if (token) {
                    dispatch('validateToken', {access_token: token})
                }
            } 

        },
        validateToken: ({commit, dispatch}, payload) => {
            commit('setAccessToken', payload.access_token)
            let options = {
                headers: {Authorization: payload.access_token}
            }
            axios.post(endPoints.oauth + "/validate_token", {}, options).then((response) => {
                console.log("Token setado", response.data.access_token)
                
                dispatch('getUser', response.data) 
                
            }).catch((error) => {
                console.log('cai aquii', error)
                dispatch('unauthenticated')
            })
        },
        redirectLogin: () => {
            let loginHomolog = `${endPoints.homolog_external_login_url}?redirectUrl=${completeUrl}/loginsx`
            let loginProd = `${endPoints.external_login_url}?redirectUrl=${completeUrl}/loginsx`
            let loginUrl = isLocalHost( window.location.host) || isHomolog(window.location.host) ? loginHomolog : loginProd
            window.location.href = loginUrl
            console.log(loginUrl)
        },
        unauthenticated: ({ commit, dispatch }) => {
            commit('setAccessToken', '' )
            dispatch('redirectLogin')
        },
    },
}