var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-overlay',{attrs:{"value":_vm.overlay}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1),_c('v-snackbar',{attrs:{"color":_vm.notificationType,"center":"","top":"","timeout":2000},model:{value:(_vm.showNotification),callback:function ($$v) {_vm.showNotification=$$v},expression:"showNotification"}},[_vm._v(" "+_vm._s(_vm.notificationMessage)+" ")]),_c('v-toolbar',{staticClass:"elevation-5",attrs:{"flat":""}},[_c('div',[_c('v-toolbar-title',[_vm._v("Pix")])],1),_c('v-spacer'),_c('div',{staticClass:"button-toolbar"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({on, attrs}){return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-2",attrs:{"color":"black","dark":"","small":""},on:{"click":function($event){return _vm.downloadXlsx({ show: true })}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-file-excel")])],1)]}}])},[_c('span',[_vm._v("Gerar Planilha")])]),_c('v-btn',{staticClass:"ma-2",attrs:{"color":"black","dark":"","small":""},on:{"click":function($event){return _vm.showFilter({show: true})}}},[_c('v-icon',[_vm._v("mdi-filter")])],1),_c('v-btn',{staticClass:"ma-2",attrs:{"color":"black","dark":"","small":""},on:{"click":_vm.getItems}},[_c('v-icon',[_vm._v("mdi-refresh")])],1)],1)],1),_c('v-data-table',{staticClass:"elevation-10 table-margin",attrs:{"headers":_vm.headers,"loading":_vm.loading,"items":_vm.items,"item-class":_vm.getClassColor,"options":_vm.pagination,"server-items-length":_vm.totalItems,"no-data-text":"Nenhuma despesa encontrada","footer-props":{
          'items-per-page-options': [10, 20, 50, 100],
          'items-per-page-text': 'Itens por página',
          'show-first-last-page': true,
          'page-text': `Página ${_vm.pagination.page} de ${
              parseInt(_vm.totalItems / _vm.pagination.itemsPerPage) + 1
          }`,
      }},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:`item.date`,fn:function({ item }){return [_c('span',[_vm._v(_vm._s(_vm.getDate(item.date)))])]}},{key:`item.type`,fn:function({ item }){return [_c('v-chip',{staticClass:"ma-2",attrs:{"color":item.type == 1 ? 'green' : 'red',"text-color":"white","label":""}},[_vm._v(" "+_vm._s(item.type == 1 ? "Entrada" : "Saída")+" ")])]}},{key:`item.depositId`,fn:function({ item }){return [_c('span',[_vm._v(_vm._s(_vm.getTransactionId(item)))])]}},{key:`item.withdrawalId`,fn:function({ item }){return [_c('span',[_vm._v(_vm._s(_vm.getTypeTransaction(item)))])]}},{key:`item.status`,fn:function({ item }){return [_c('span',[_vm._v(_vm._s(_vm.getStatus(item.status)))])]}},{key:`item.used`,fn:function({ item }){return [_c('v-chip',{staticClass:"ma-2",attrs:{"color":_vm.isPixUsed(item) ? 'blue' : 'green',"text-color":"white","label":""}},[_vm._v(_vm._s(_vm.isPixUsed(item) ? "Sim" : "Não"))])]}},{key:`item.value`,fn:function({ item }){return [_c('span',[_vm._v(_vm._s(parseFloat(item.value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', })))])]}}],null,true)}),_c('v-dialog',{attrs:{"value":_vm.dialogFilter,"width":"600"},on:{"click:outside":function($event){return _vm.showFilter({show: false})}}},[_c('pix-filter')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }