import axios from "axios";
import { getField, updateField } from "vuex-map-fields";
import endPoints from "@config/endPoints";
import formatDate from '../../../util/formatDate.js'

const defaultFilter = {
  id: "",
  type_order: "",
  id_oper: "",
  bot_id: "",
  club: "",
  id_pppoker: "",
  status: "",
  id_instance: "",
};
const defaultFinishItem = {
  print_img: "",
  manager: null,
  club: null
};
const defaultItem = {
  external_id: null,
  value: null,
  player_id: "",
  slot: "",
  type: null,
  origin: null,
  date_created: "",
  description: "",
  user_id: null,
  bot_origin: null,
  platform: null,
  user_finish_name: null,
  user_created_name: null,
  observation: "",
  id_double_check: null,
  manual_order: null,
};

const defaultManualOrderItem = {
  external_id: null,
  value: null,
  player_id: "",
  slot: "",
  type: null,
  origin: null,
  date_created: "",
  description: "",
  bot_origin: "",
  user_id: null,
  user_finish_name: null,
  user_created_name: null,
  platform: null,
  observation: "",
  id_double_check: null,
  manual_order: null,
  plataform_id: null
};

export default {
  namespaced: true,
  state: {
    botDialog: false,
    dialogFilter: false,
    dialogFinish: false,
    dialogBotsForm: false,
    showDialogInfo: false,
    manualOrderDialog: false,
    dialogPrint: false,
    finishItem: {},
    selectedItem: {},
    orderToDoubleCheck: {},
    doubleCheck: false,
    filter: {
      id: "",
      type: "",
      id_instance: "",
      external_id: "",
      origin: "",
      idbot: "",
      player_id: "",
      status: "",
      club: "",
      value: "",
      slot: "",
      start: "",
      end: "",
      awaiting_validation: "",
    },
    origins: [],
    slots: [],
    items: [],
    instances: [],
    managers: [],
    bots: [],
    filtered: false,
    loading: false,
    mode: "LIST",
    overlay: false,
    page: 1,
    pagination: {
      page: 1,
      itemsPerPage: 10,
    },
    successNotification: false,
    notificationMessage: "",
    notificationType: "success",
    showNotification: false,
    totalItems: 0,
    rowsPerPage: 10,
    print: '',
  },
  mutations: {
    view: (state, payload) => {
      state.selectedItem = payload.item;
      state.manualOrderDialog = true;
      state.mode = "VIEW";
    },
    add: (state) => {
      state.selectedItem = Object.assign({}, defaultManualOrderItem);
      state.manualOrderDialog = true;
      state.mode = "ADD";
    },
    setOrigins: (state, payload) => (state.origins = payload),
    loading: (state, payload) => (state.loading = payload.show),
    setMode: (state, mode) => (state.mode = mode),
    setSlots: (state, payload) => (state.slots = payload),
    setItems: (state, items) => (state.items = items),
    setBots: (state, bots) => (state.bots = bots),
    setManagers: (state, managers) => (state.managers = managers),
    setTotalItems: (state, total) => (state.totalItems = total),
    setRowsPerPage: (state, rowsPerPage) => (state.rowsPerPage = rowsPerPage),
    showDialog: (state, show) => (state.dialog = show),
    setInstances: (state, payload) => state.instances = payload.instances,
    setDoubleCheck: (state, payload) => state.doubleCheck = payload.isDoubleCheck,
    setOrderToDoubleCheck: (state, payload) => state.orderToDoubleCheck = payload.order,
    setFilter: (state, filter) => {
      state.filter = filter
    },
    setFiltered: (state, filtered) => state.filtered = filtered,
    setPage: (state, payload) => {
      state.pagination.page = payload.page;
      state.pagination.itemsPerPage = payload.itemsPerPage;
    },
    setPrint: (state, payload) => {
      state.print = payload.print
      state.dialogPrint = true
    },
    showDialogPrint(state, payload) {
      state.dialogPrint = payload.show
    },
    showDialogFinish(state, payload) {
      state.finishItem = payload.item
      state.dialogFinish = true
    },
    closeManualOrderDialog(state) {
      state.selectedItem = Object.assign({}, defaultFinishItem);
      state.manualOrderDialog = false
    },
    closeDialogFinish(state) {
      state.finishItem = Object.assign({}, defaultFinishItem);
      state.dialogFinish = false
    },
    closeBotsFormDialog(state) {
      state.selectedItem = Object.assign({}, defaultItem);
      state.dialogBotsForm = false
      state.manualOrderDialog = false;
      state.mode = "LIST"
    },
    closeDialogInfo(state) {
      state.selectedItem = Object.assign({}, defaultItem);
      state.showDialogInfo = false
      state.mode = "LIST"
    },
    showFilter: (state, payload) => (state.dialogFilter = payload.show),
    showBotDialog: (state, payload) => (state.botDialog = payload.show),
    showBotsFormDialog: (state, payload) => (
      state.dialogBotsForm = payload.show
    ),
    showErrorNotification: (state, show) => (state.errorNotification = show),
    showSuccessNotification: (state, show) =>
      (state.successNotification = show),
    showLoading: (state, show) => (state.loading = show),
    showNotification: (state, payload) => {
      state.showNotification = payload.show
      state.notificationMessage = payload.message
      state.notificationType = payload.type
    },
    showOverlay: (state, payload) => {
      state.overlay = payload.show;
    },
    showManualOrderDialog: (state, payload) => {
      state.manualOrderDialog = payload.show;
    },
    viewItem: (state, payload) => {
      state.selectedItem = payload.item;
      state.mode = "VIEW";
    },
    updateField
  },
  actions: {
    searchSlots: ({ commit }, payload) => {

      let url = endPoints.financial_bots;
      url += "/search_slot/" + payload.search;
      console.log("🚀 ~ file: bots.js ~ line 177 ~ url", url)

      axios.get(url).then((items) => {
        commit("setSlots", items.data.data);
      });
    },
    getPossiblesOrigins: ({ commit }) => {
      let url = endPoints.orders + "/origins";

      axios.get(url).then((items) => {
        commit("setOrigins", items.data.data);
      });
    },
    setOrderFinish: ({ dispatch, commit, state }, payload) => {

      commit("showDialogFinish", { item: payload.item })
      state.mode = "EDIT"
      dispatch("getManagers")
    },
    closeDialog: ({ commit }) => {
      commit("closeDialog", false);
    },
    getInstances: ({ commit }) => {
      let url = endPoints.instances;
      url += '?page=' + 1 + '&' + 'itemsPerPage=' + 50;
      axios.get(url).then(instances => {
        commit('setInstances', { instances: instances.data.data })
      });
    },
    clearFilter: ({ commit, dispatch }) => {
      commit('setFilter', Object.assign({}, defaultFilter))
      commit('showFilter', { show: false })
      commit('showOverlay', { show: true })

      setTimeout(function () {
        commit('showOverlay', { show: false })
        dispatch('getItems', { resetPage: true })
      }, 100);
    },
    getBots: ({ commit }) => {
      let url = endPoints.bots;
      axios.get(url).then((items) => {
        commit("setBots", items.data.data);
      });
    },
    getManagers: ({ commit, state }) => {
      let url = endPoints.managers;
      url += "?page=" + 1 + "&" + "itemsPerPage=" + 999 + "&" + "club=" + state.finishItem.club;
      axios.get(url).then((items) => {
        commit("setManagers", items.data.data);
      });
    },
    findTransactionPrint: ({ commit }, payload) => {
      commit('loading', { show: true })
      const url = endPoints.orders + '/operation/' + payload.item.external_id + "?origin=" + payload.item.origin;
      let img = ''
      if (payload.finished_by == 0) {
        img = 'data:image/jpeg;base64,'
      }
      axios.get(url).then(print => {
        img += print.data.transaction_print
        commit('setPrint', { print: img })
        commit('loading', { show: false })
      }).catch(() => {
        commit('loading', { show: false })
        let notification = {
          show: true,
          message: "Não foi possível encontrar o print da transação",
          type: "error"
        }
        commit('showNotification', notification)
      });
    },
    activateBot: ({ dispatch }, payload) => {
      let url = endPoints.bots + "/" + payload.item.id;
      payload.item.is_active = payload.is_active;
      axios.put(url, payload.item).then(() => {
        dispatch("getBots");
      });
    },
    checkOrderTimeOut: ({ dispatch, commit }, payload) => {
      let url = endPoints.orders + "/check_status_order_timeout";
      axios.post(url, payload.item).then(() => {
        let notification = {
          show: true,
          message: "Ordem atualizada com sucesso",
          type: "success"
        }
        commit('showNotification', notification)
        dispatch("getItems", { resetPage: false });
      }).catch((error) => {
        let notification = {
          show: true,
          message: error?.response?.data?.message,
          type: "error"
        }
        commit('showNotification', notification)
      })
    },
    updateActionBot: ({ dispatch }, payload) => {
      let url = endPoints.bots + "/update/" + payload.item.id;
      payload.item.action_bot = payload.action_bot;
      axios.put(url, payload.item).then(() => {
        dispatch("getItems");
      });
    },
    updateTimeoutOrder: ({ dispatch }, payload) => {
      axios.put(endPoints.orders + "/by_order_id/" + payload.id, payload).then(() => {
        dispatch("getItems", { resetPage: false });
      })
    },
    finishOrder: async ({ dispatch, commit }, payload) => {
      let data = payload.item
      data.manager = parseInt(data.manager)
      var file = data.transaction_print;
      const toBase64 = (file) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = (error) => reject(error);
        });

      let base64_file = await toBase64(file);
      data.transaction_print = base64_file
      data.status = 2;
      data.finished_by = 1;
      let url = endPoints.orders + "/by_order_id/" + payload.item.id;
      axios.put(url, data).then(() => {
        commit('closeDialogFinish')
        dispatch("getItems", { resetPage: false });
        dispatch("getBots");
      });
    },
    getItems: ({ commit, state }, payload) => {
      commit("loading", { show: true });
      let url = endPoints.orders;

      let filter = state.filter;
      if (payload.resetPage === true) {
        url += "?page=" + 1 + "&" + "itemsPerPage=" + 10 + "&sort_by=id&sort_desc=true";
        commit("setPage", { page: 1, itemsPerPage: 10 });
      } else {
        url +=
          "?page=" +
          state.pagination.page +
          "&" +
          "itemsPerPage=" +
          state.pagination.itemsPerPage;
        +"&sort_by=id&sort_desc=true";
      }
      commit('setFiltered', false)
      Object.keys(filter).forEach(function (field) {
        if (filter[field] !== "" && filter[field] !== undefined && filter[field] !== null) {
          commit('setFiltered', true)

          if (field == "end" || field == "start") {
            var e = new Date(filter[field])
            url += "&" + field + "=" + formatDate(e) + " " + e.toLocaleTimeString()
          } else {
            url += "&" + field + "=" + filter[field]
          }
        }
      });

      if (state.pagination && state.pagination.sortBy && state.pagination.sortBy.length > 0) {
        url += "&sort_by=" + state.pagination.sortBy[0]
        url += "&sort_desc=" + state.pagination.sortDesc[0]
      }

      axios.get(url).then((items) => {
        commit("setTotalItems", items.data.total);
        commit("loading", { show: false });
        commit("setItems", items.data.data);
      });
    },
    checkAccount: ({ state, dispatch, commit }, payload) => {
      state.mode = "ADD"
      commit("setOrderToDoubleCheck", { order: payload.item })
      commit('setDoubleCheck', { isDoubleCheck: true })
      dispatch("getSlotByIdApp")
    },
    getSlotByIdApp: async ({ state, dispatch }) => {
      let url = endPoints.slots;
      url += "?page=1&itemsPerPage=999&id_app=" + state.orderToDoubleCheck.slot;
      await axios.get(url).then((items) => {
        state.orderToDoubleCheck.platform = items.data.data[0].plataform_id
      })
      dispatch("save")

    },
    forceNotification({ commit }, payload) {
      axios.put(endPoints.orders + '/force_notification/' + payload.item.id).then(() => {
        let notification = {
          show: true,
          message: "Notificação enviada com sucesso!",
          type: "success"
        }
        commit('showNotification', notification)
      }).catch(() => {
        let notification = {
          show: true,
          message: "Erro ao enviar notificação!",
          type: "error"
        }
        commit('showNotification', notification)
      })
    },
    save: async ({ commit, state, dispatch, rootState }) => {
      let data;
      if (state.doubleCheck) {
        data = state.orderToDoubleCheck
        data.type = 0;
      } else {
        data = state.selectedItem
      }
      let url = endPoints.orders
      let func = ""

      state.mode === "ADD"
        ? (func = axios.post)
        : ((func = axios.put), (url += `/${data.id}`))

      commit("loading", { show: true })
      try {
        data.user_id = rootState.login.userData.id
        data.user_created = rootState.login.userData.id
        data.value = parseFloat(data.value)
        data.external_id = parseInt(data.external_id)
        data.slot = data.slot.toString()
        data.manual_order = 1

        await func(url, data)

        let notification = {
          show: true,
          message: "Ordem manual criada com sucesso!",
          type: "success"
        }
        commit("loading", { show: false })
        commit('showNotification', notification)
        dispatch('getItems', { resetPage: false })
        commit("closeBotsFormDialog")
        commit("closeManualOrderDialog")
      } catch (error) {
        console.error(error);
        console.error(error.response.data.message);
        console.log(error?.message)
        let notification = {
          show: true,
          message: error?.response?.data?.message || "Erro no formulário ao salvar ordem manual!",
          type: "error"
        }
        console.log("🚀 ~ file: bots.js ~ line 378 ~ save: ~ notification", notification)
        commit('showNotification', notification)
        commit("loading", { show: false })
      }
      commit('setDoubleCheck', { isDoubleCheck: false })
      commit('setOrderToDoubleCheck', {})
    },
  },
  getters: {
    getField,
  },
};
