<template>
  <div>
    <div @drop.prevent="addDropFile" @dragover.prevent>

      <v-card>
        <v-card-title>
          <span class="headline">Depósito</span>
          <v-spacer></v-spacer>
          <v-progress-circular
            v-if="loading"
            indeterminate
            class="text-right"
            color="primary"
            size="24"
          ></v-progress-circular>

          <v-expansion-panels v-if="screen == 'messages'">
            <v-expansion-panel>
              <v-expansion-panel-header
                class="message-with-header"
              >Mensagem De: {{ contact }} | {{instance_name}}</v-expansion-panel-header>
              <v-expansion-panel-content class="message-with-content">
                <v-textarea
                  auto-grow
                  outlined
                  hide-details="true"
                  rows="5"
                  :value="message_deposit"
                ></v-textarea>
                <v-card v-if="fileBase64">
                  <v-img :src="fileBase64" contain></v-img>
                </v-card>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-title>

        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-container grid-list-md>
              <v-layout wrap>
                
                <v-flex xs12 sm6 md6>
                  <v-select
                    label="Status *"
                    :items="possible_status"
                    v-model="status"
                    :readonly="true"
                    :error-messages="errors.collect('status')"
                    :rules="[rules.requiredAcceptZero]"
                    item-text="description"
                    item-value="id"
                    data-vv-name="status"
                    outlined
                  ></v-select>
                </v-flex>
                <v-flex xs12 sm6 md6>
                  <v-text-field
                    required
                    label="Valor *"
                    v-model="value"
                    :readonly="this.mode == 'VIEW' || this.mode == 'FINISH'"
                    :error-messages="errors.collect('value')"
                    :rules="[rules.required, rules.decimal]"
                    data-vv-name="value"
                    outlined
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md6>
                  <v-select
                    label="Tipo de Pagamento *"
                    :items="this.mode == 'VIEW' ? payment_types_info : payment_types"
                    v-model="payment_type"
                    :readonly="this.mode == 'VIEW' || this.mode == 'FINISH' || this.type == 4" 
                    :rules="[rules.requiredAcceptZero]"
                    item-text="description"
                    item-value="id"
                    outlined
                    @change="type = null"
                  ></v-select>
                </v-flex>
                <v-flex xs12 sm6 md6>
                  <v-select
                    label="Tipo de Envio *"
                    @change="typeSelectOnChange()"
                    :disabled="!this.payment_type && this.payment_type != 0"
                    :items="typeByPaymentType"
                    v-model="type"
                    :readonly="this.mode == 'VIEW' || this.mode == 'FINISH'"
                    :rules="[rules.requiredAcceptZero]"
                    item-text="description"
                    item-value="id"
                    outlined
                  ></v-select>
                </v-flex>
                <v-flex v-if="payment_type == 0 || payment_type == 3" xs12 sm6 md6>
                  <v-select
                    :clearable="true"
                    clear-icon="mdi-close"
                    label="Banco *"
                    :items="bankDeposits"
                    v-model="bank"
                    :readonly="this.mode == 'VIEW' || this.mode == 'FINISH' || this.type == 4"
                    :disabled="this.mode == 'VIEW' || this.mode == 'FINISH' || this.type == 4"
                    :error-messages="errors.collect('bank')"
                    :rules="[rules.requiredAcceptZero]"
                    :item-text="getTextBank"
                    item-value="id"
                    data-vv-name="bank"
                    outlined
                  ></v-select>
                </v-flex>
                <v-flex v-if="showPlayerFields()" xs12 sm6 md6>
                  <v-text-field
                    required
                    :label="getPlayerLabel('ID Suprema')"
                    v-model="id_pppoker"
                    :readonly="this.mode == 'VIEW' || this.mode == 'FINISH'"
                    :rules="[rules.required, rules.max_length(id_pppoker, 10), rules.onlyNumbers]"
                    outlined
                  ></v-text-field>
                </v-flex>
                <v-flex v-if="showPlayerFields()" xs12 sm6 md6>
                  <v-autocomplete
                    :label="getPlayerLabel('Plataforma')"
                    :items="plataforms"
                    v-model="plataform"
                    :readonly="this.mode == 'VIEW' || this.mode == 'FINISH'"
                    item-text="description"
                    item-value="id"
                    :rules="[rules.requiredAcceptZero]"
                    outlined
                    @change="id_instance = null"
                  ></v-autocomplete>
                </v-flex>
                <v-flex v-if="showPlayerFields()" xs12 sm6 md6>
                  <v-autocomplete
                    :label="getPlayerLabel('Clube')"
                    :items="clubsByPlatform"
                    v-model="id_instance"
                    :disabled="!plataform && plataform != 0"
                    :readonly="this.mode == 'VIEW' || this.mode == 'FINISH'"
                    :item-text="getTextSlot"
                    item-value="id"
                    :rules="[rules.requiredAcceptZero]"
                    outlined
                  ></v-autocomplete>
                </v-flex>
                <v-flex v-if="type == 2" xs12 sm6 md6>
                  <v-text-field
                    required
                    label="CPF/CNPJ *"
                    v-model="pix_identifier"
                    :readonly="this.mode == 'VIEW' || this.mode == 'FINISH'"
                    :rules="[rules.max_length(pix_identifier, 30), rules.cpf_cnpj, rules.required]"
                    outlined
                  ></v-text-field>
                </v-flex>
                <v-flex v-else xs12 sm6 md6>
                  <v-text-field
                    required
                    label="CPF/CNPJ"
                    v-model="pix_identifier"
                    :readonly="this.mode == 'VIEW' || this.mode == 'FINISH'"
                    :rules="[rules.max_length(pix_identifier, 30)]"
                    outlined
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md6>
                  <v-text-field
                    required
                    label="Identificador"
                    v-model="endToEndId"
                    :readonly="true"
                    :error-messages="errors.collect('endToEndId')"
                    :rules="[rules.max_length(endToEndId, 100)]"
                    data-vv-name="endToEndId"
                    outlined
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm8 md8>
                  <v-textarea
                    auto-grow
                    outlined
                    label="Observação"
                    id="textareamessage"
                    hide-details="true"
                    rows="1"
                    :rules="[rules.max_length(observation, 254)]"
                    v-model="observation"
                    :value="observation"
                  ></v-textarea>
                </v-flex>
                <v-flex xs2 sm2 md2>
                  <v-checkbox
                    v-model="bonus"
                    label="Dar Bônus"
                    :rules="[rules.bonus(bonus, pix_identifier)]"
                  ></v-checkbox>
                </v-flex>
                <v-flex xs2 sm2 md2>
                  <v-checkbox
                    v-model="free_roll"
                    label="Free Roll"
                  ></v-checkbox>
                </v-flex>
                <v-expansion-panels v-if="this.mode == 'VIEW'">
                  <v-expansion-panel>
                    <v-expansion-panel-header>Auditoria</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-layout wrap>
                        <v-flex xs12 sm6 md6>
                          <v-text-field
                            required
                            label="Criado em"
                            :readonly="true"
                            outlined
                            :value="getDate(this.date_created)"
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm6 md6>
                          <v-text-field
                            required
                            label="Finalizado em"
                            :readonly="true"
                            outlined
                            :value="getDate(this.finish_at)"
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm6 md6>
                          <v-text-field
                            required
                            label="Criado Por"
                            :readonly="true"
                            outlined
                            :value="this.created_user_name"
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm6 md6>
                          <v-text-field
                            required
                            label="Finalizado Por"
                            :readonly="true"
                            outlined
                            :value="this.finish_user_name"
                          ></v-text-field>
                        </v-flex>
                      </v-layout>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
                <v-expansion-panels v-if="this.mode == 'VIEW' && this.pix_id">
                  <v-expansion-panel>
                    <v-expansion-panel-header>Pix</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-layout wrap>
                        <v-flex xs12 sm4 md4>
                          <v-text-field
                            required
                            label="CPF/CNPJ"
                            :readonly="true"
                            outlined
                            :value="pix.client_key"
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm4 md4>
                          <v-text-field
                            required
                            label="Data"
                            :readonly="true"
                            outlined
                            :value="getDate(pix.date)"
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm4 md4>
                          <v-text-field
                            label="Valor"
                            :readonly="true"
                            outlined
                            :value="pix.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })"
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm12 md12>
                          <v-text-field
                            label="Identificador"
                            :readonly="true"
                            outlined
                            :value="pix.endToEndId ? pix.endToEndId : pix.txId"
                          ></v-text-field>
                        </v-flex>
                      </v-layout>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
                <v-flex xs12 sm12 md12>
                  <v-file-input
                    class="mt-2"
                    v-model="receipt_file"
                    v-if="this.mode == 'ADD'"
                    label="Comprovante"
                    outlined
                  ></v-file-input>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-col>
            <v-btn text @click="closeDialog">{{ mode == 'VIEW' ? 'Voltar' : 'Cancelar'}}</v-btn>
          </v-col>
          <v-col class="text-right" v-if="mode != 'VIEW'">
            <v-btn text type="submit" right @click="saveDeposit" :disabled="block_click">Salvar</v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
import { mapFields } from "vuex-map-fields";
import toBase64 from '@util/Base64'
import valida_cpf_cnpj from "@util/CpfCnpj"

export default {
  name: "DepositForm",
  computed: {
    ...mapGetters("login", ["getInstanceId"]),
    ...mapState("bank", { banks: "items" }),
    ...mapState("slots", [
      "slots"
    ]),
    ...mapState("deposit", [
      "contact",
      "item",
      "instance_name",
      "loading",
      "mode",
      "pix"
    ]),
    ...mapFields("deposit", [
      "editedItem.id",
      "editedItem.code",
      "editedItem.bank",
      "editedItem.date",
      "editedItem.created_user_name",
      "editedItem.finish_user_name",
      "editedItem.date_created",
      "editedItem.finish_at",
      "editedItem.date_picker",
      "editedItem.value",
      "editedItem.club",
      "editedItem.id_pppoker",
      "editedItem.nick",
      "editedItem.status",
      "editedItem.payment_type",
      "editedItem.group_message",
      "editedItem.receipt",
      "editedItem.receipt_file",
      "editedItem.instance_id",
      "editedItem.type",
      "editedItem.name",
      "editedItem.observation",
      "editedItem.id_instance",
      "editedItem.sent_id_pppoker",
      "editedItem.sent_nick",
      "editedItem.sent_id_instance",
      "editedItem.pix_identifier",
      "editedItem.validation_state",
      "editedItem.pix_id",
      "editedItem.endToEndId",
      "editedItem.bonus",
      "editedItem.bonus_id",
      "editedItem.free_roll",
      "message_deposit",
      // ...
    ]),
    clubsByPlatform() {
      return this.slots.filter((slot) => slot.plataform_id == this.plataform);
    },
    bankDeposits() {
      return this.banks.filter((bank) => bank.receive_deposit == 1);
    },
    messageTreated() {
      if (this.message_deposit) {
        var messageTreated = this.message_deposit
          .replace(/&/g, "&amp;")
          .replace(/</g, "&lt;")
          .replace(/>/g, "&gt;")
          .replace(/"/g, "&quot;")
          .replace(/'/g, "&#039;")
          .replace(/\n/g, "<br>")
          .replace(/\*([^]+?)\*/g, "<b>$1</b>");
        return messageTreated;
      } else {
        return "";
      }
    },
    fileBase64: function() {
      if(this.fileString) {
        return this.fileString
      }
      return ''
    },
    typeByPaymentType() {
      let possible_types =  [
        { id: 0, description: "Envio de Fichas Jogador"},
      //  { id: 1, description: "Envio de Fichas Agência"},
        { id: 2, description: "Abater Anotados"},
        { id: 3, description: "Adicionar ao Saldo Positivo"},
        { id: 5, description: "Bônus"},
        { id: 6, description: "Reembolso"}
      ]
      console.log(this.payment_type)
      switch(this.payment_type) {
        case 0:
          console.log('entrei aqui')
          return possible_types.filter((type) => type.id == 0 || type.id == 2 || type.id == 3)
        case 1:
          return possible_types.filter((type) => type.id == 0)
        case 2:
          return possible_types.filter((type) => type.id == 0)
        case 3:
          return possible_types.filter((type) => type.id == 0 || type.id == 2 || type.id == 3)
        case 4: 
          return possible_types.filter((type) => type.id == 5)
        case 5:
          return possible_types.filter((type) => type.id == 0)  
        case 7:
          return possible_types.filter((type) => type.id == 0 || type.id == 3) 
      }
      console.log('passei de tudo')
      return []
    }
  },
  created() {
    this.setScreen({screen: this.$props.screen})
    
    this.getSlots({ resetPage: false, noPages: true, onlyIfNotSearched: true });
    this.$validator.extend("required", {
      getMessage: (field, [label]) => label + " deve ser informado",
      validate: (_, [, field]) => {
        if (this[field] || this[field] === 0) {
          return true;
        } else {
          return false;
        }
      },
    });
    this.$validator.extend("max_length", {
      getMessage: (_, [length]) => "No máximo " + length + " caracteres.",
      validate: (val, [length]) => {
        if (val.length > length) {
          return false;
        } else {
          return true;
        }
      },
    });
  },
  methods: {
    ...mapActions("slots", [
      "getSlots",
    ]),
    ...mapActions("deposit", ["closeDialog", "save"]),
    ...mapMutations("deposit", ["setScreen", "setBank", "setPaymentType"]),
    addDropFile(e) {
      if (this.mode == "ADD" || this.mode == "EDIT") {
        if (e.dataTransfer) {
          if (e.dataTransfer.files[0]) {
            this.receipt_file = e.dataTransfer.files[0];
          }
        }
      }
    },
    getTextSlot(item) {
      let plataform = ""
      if(item.plataform_id == 0) {
        plataform = "Suprema"
      } else if(item.plataform_id == 1) {
        plataform = "Pppoker"
      } else if(item.plataform_id == 2) {
        plataform = "Cacheta"
      }
      return plataform + " | " +item.name
    },
    getAlertMessage() {
      if (typeof this.bank == "object") {
        return this.bank.observation;
      }
      return "";
    },
    getAccountType(type) {
      switch (type) {
        case 0:
          return "Corrente";
        case 1:
          return "Poupança";
      }
    },
    getDate(date) {
      if (date) {
        var d = new Date(date);
        return d.toLocaleDateString() + " " + d.toLocaleTimeString();
      }
      return "";
    },
    getPlayerLabel(label) {
      let newLabel = label
      if (this.type === 4)
        newLabel += ' (Enviada)'
      
      newLabel += ' *'
      return newLabel
    },
    getTextBank(item) {
      if(item.account) {
        return item.name + ' | ' + item.account
      } else {
        return item.name
      }
    },
    setFileBase64() {
      if(this.receipt_file && this.receipt_file.name) {
        toBase64(this.receipt_file).then((fileBase64) => {
          this.fileString = fileBase64
        })
      }
      else {
        this.fileString = ''
      }
    },
    saveDeposit() {
      let valid = this.$refs.form.validate()
      
      if (valid) {
        this.block_click = true
        if (this.date_picker) {
          var d = new Date(this.date_picker);
          this.date =
            d.toISOString().substr(0, 10) + " " + d.toLocaleTimeString();
        }

        this.bonus = this.bonus ? 1 : 0
        this.free_roll = this.free_roll ? 1 : 0
        
        this.value = parseFloat(this.value);
        this.save({ origin: "DEPOSIT" }).then(() => { this.block_click = false }).catch(() => { this.block_click = false });
      }
    },
    typeSelectOnChange() {
      let idTransfer = this.banks.filter((bank) => bank.code === '1000')[0].id
      if(this.type == 4) {
        this.setBank({bank: idTransfer})
        this.setPaymentType({paymentType: 0})
      } else if (this.bank === idTransfer){
        this.setBank({bank: ''})
      }
    },
    showPlayerFields() {
      let possiblePaymentTypes = [0,1,2,3,5]
      let possibleType = 0
      return (possiblePaymentTypes.includes(this.payment_type) || (!this.payment_type && this.paymentType != 0)) && (this.type == possibleType || (!this.type && this.type != 0))
    }
  },
  props: ["screen"],
  data: () => ({
    plataform: null,
    plataforms: [
      { id: 0, description: "Suprema Poker" },
      { id: 1, description: "Pppoker" },
      { id: 2, description: "Cacheta"},
    ],
    rules: {
      bonus(bonus, pix_identifier) {
        if(bonus) {
          if(!pix_identifier) {
            return "CPF/CNPJ deve ser informado"
          } else {
            return valida_cpf_cnpj(pix_identifier.replaceAll(".", "").replaceAll("-", "").replaceAll("/", "")) || 'CPF/CNPJ inválidos'
          }
        } else {
          return true
        }
      },
      cpf_cnpj(value) {
        if(!value) return true
        else {
          return valida_cpf_cnpj(value.replaceAll(".", "").replaceAll("-", "").replaceAll("/", "")) || 'CPF/CNPJ inválidos'
        }
      },
      onlyNumbers(value) {
        return !! parseInt(value) || "Valor deve ser númerico"
      },
      decimal(value) {
        let regex = /^[-]?\d*(\.\d+)?$/
        return regex.test(value) || 'Valor deve ser númerico'
      },
      required(value) { 
        return !!value || 'Campo Obrigatório'
      },
      requiredAcceptZero(value) { 
        return !!value || value === 0 || 'Campo Obrigatório'
      },
      max_length(value, max_length) {
        if(value && value.length > max_length) return 'Máximo de ' + max_length + ' caracteres.'
        return true
      }
    },
    block_click: false,
    payment_types: [
      { id: 0, description: "Transferência" },
      { id: 3, description: "Pix" },
      { id: 1, description: "Anotado" },
      { id: 2, description: "Troca de Ticket" },
      { id: 5, description: "Ganhos/Perdas" },
      { id: 7, description: "Reembolso" }
    ],
    payment_types_info: [
      { id: 0, description: "Transferência" },
      { id: 1, description: "Anotado" },
      { id: 2, description: "Troca de Ticket" },
      { id: 3, description: "Pix" },
      { id: 4, description: "Bônus"},
      { id: 5, description: "Ganhos/Perdas" },
      { id: 6, description: "Fichas" },
      { id: 7, description: "Reembolso" }
    ],
    possible_status: [
      { id: 0, description: "Pendente" },
      { id: 1, description: "Em Andamento" },
      { id: 2, description: "Concluido" },
      { id: 3, description: "Estornado" },
    ],
    possible_types: [
      { id: 0, description: "Envio de Fichas Jogador"},
      { id: 1, description: "Envio de Fichas Agência"},
      { id: 2, description: "Abater Anotados"},
      { id: 3, description: "Adicionar ao Saldo Positivo"},
      { id: 5, description: "Bônus"},
      { id: 6, description: "Reembolso"}
    ],
    fileString: '',
    timeProps: {
      format: "24hr",
    },
    valid: false,
  }),
  watch: {
    receipt_file: {
      handler() {
        this.setFileBase64();
      },
    },
  },
};
</script>


<style>
@import "./css/deposit.css";
</style>